@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Bold.eot");
  src: url("./assets/font/SFProDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/SFProDisplay-Bold.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Bold.woff") format("woff"),
    url("./assets/font/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Medium.eot");
  src: url("./assets/font/SFProDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/SFProDisplay-Medium.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Medium.woff") format("woff"),
    url("./assets/font/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Regular.eot");
  src: url("./assets/font/SFProDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/SFProDisplay-Regular.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Regular.woff") format("woff"),
    url("./assets/font/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: "SF Pro Display";
  font-weight: 400;
  background-color: #000203;
  color: #ffffff;
  overflow-x: hidden;
}

a {
  color: #00ffcc;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  /* text-decoration: underline; */
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
}

.main_navbar {
  position: fixed;
  width: 100%;
  z-index: 100;
  padding: 10px 0;
}
.buytoken .head_box {
  padding: 20px;
  background: linear-gradient(133deg, #070807 40%, #010102);
}

.buytoken .head_box h5 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 3px;
}

.footer_url {
  color: #49c527;
}
.footer_url:hover {
  color: #49c527;
  text-decoration: underline;
}
h5.head_box_price span {
  color: #49c527;
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
  /* margin-left: 0; */
}

.buytoken .head_box p span {
  color: #ffffff;
}
a.history_table {
  color: #7f8076;
  text-decoration: underline;
}

a.history_table:hover {
  color: #7f8076;
}

.navbar-brand {
  outline: 0 !important;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #000203;
  margin-top: 0px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar_right {
  display: flex;
  align-items: center;
  width: 100%;
}

.offcanvas .links {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  border-radius: 999px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(255, 255, 255, 0.02);
  padding: 12px 34px;
  margin: 0 auto;
}

.offcanvas .links li {
  margin: 0 23px;
}

.offcanvas .links li a {
  color: #afafaf;
  cursor: pointer;
}

.offcanvas .links li a:hover,
.offcanvas .links li a.active {
  color: #fff;
  text-decoration: none !important;
}

/**** tick symbol withdraw******/
svg {
  width: 80px;
  display: block;
  margin: 23px auto 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;

  &.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
  }

  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }

  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
}

p {
  /* text-align: center; */
  margin: 20px 0 60px;
  font-size: 1.25em;

  &.success {
    color: #73af55;
  }

  &.error {
    color: #d06079;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

/**** tick symbol withdraw******/
/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(180deg, #000203 0%, #00060c 100%);
}

.primary_modal .modal-header {
  border-bottom: 1px solid #292c2e;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #292c2e;
  background-color: transparent;
  color: #000;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.buytoken select {
  border: 1px solid #1a1d21;
  margin-bottom: 15px;
  padding: 10px;
  outline: 0;
  background-image: url("./assets/images/dropdown_arrow.png") !important;
  background-repeat: no-repeat !important;
  background-position: 97% center !important;
  appearance: none;
  padding-right: 35px;
}

.connect_wallet_button:hover {
  border-color: #292c2e;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  filter: invert(1);
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}

.errortext {
  color: red;
}

.primary_btn {
  border-radius: 60px;
  color: #fff !important;
  text-align: center;
  background: radial-gradient(
        112.34% 107.5% at 48.33% 53.85%,
        #3f7639 0.56%,
        #0e3142 58.56%,
        #0e3142 100%
      )
      padding-box,
    linear-gradient(to right, #ffffff, #000000, #4e4e4e) border-box;
  border: 1px solid #fff0;
  padding: 12px 34px;
  z-index: 1;
  background-size: 200px;
  position: relative;
  transition: 0.5s;
  overflow: hidden;
  text-decoration: none !important;
  display: block;
}

.primary_btn:hover {
  background: radial-gradient(
        282.34% 107.5% at 48.33% 53.85%,
        #3f7639 0.56%,
        #0e3142 58.56%,
        #0e3142 100%
      )
      padding-box,
    linear-gradient(to right, #ffffff, #000000, #4e4e4e) border-box;
  transition: 0.5s;
}

.primary_btn:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  filter: blur(30px);
  transform: translateX(-100px) skewX(-15deg);
}

.primary_btn:after {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 100%;
  left: 30px;
  top: 0;
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100px) skewX(-15deg);
}

.primary_btn:hover:before {
  transform: translateX(300px) skewX(-15deg);
  opacity: 0.6;
  transition: 1s;
}

.primary_btn:hover:after {
  transform: translateX(300px) skewX(-15deg);
  opacity: 1;
  transition: 1s;
}

.blue {
  border: 1px solid #4a7081;
  background: radial-gradient(
    112.34% 107.5% at 48.33% 53.85%,
    rgba(255, 255, 255, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    rgba(0, 0, 0, 0) 100%
  );
}

.blue:hover {
  background: radial-gradient(
    282.34% 107.5% at 48.33% 53.85%,
    rgba(255, 255, 255, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    rgba(0, 0, 0, 0) 100%
  );
}

.h2tag {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.2px;
  text-transform: capitalize;
  background: var(
    --Heading,
    linear-gradient(90deg, #fff 47.83%, #002a4a 104.53%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.h2tag span {
  font-weight: 700;
}

.head {
  max-width: 55%;
  margin: 0 auto 20px;
  text-align: center;
}

.h5tag {
  font-size: 22px;
  font-weight: 500;
}

.page_header {
  position: relative;
  padding-top: 100px;
}

.page_header::before {
  content: "";
  position: absolute;
  background: url("./assets/images/banshade.png") no-repeat center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 190%;
  background-size: cover;
  background-size: 100% 120%;
  z-index: -1;
}

.page_header::after {
  content: "";
  position: absolute;
  background: url("./assets/images/ban_right_img.png") no-repeat center;
  width: 784px;
  height: 100%;
  right: -0%;
  bottom: -48%;
  z-index: -1;
}

.page_header h1 {
  font-size: 128px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: -3.2px;
  text-transform: capitalize;
  display: inline-block;
  background: linear-gradient(90deg, #fff 36.27%, #002a4a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page_header .box {
  margin-bottom: 100px;
}

.page_header p {
  font-size: 28px;
  margin-bottom: 25px;
}

.page_header .img_box img {
  mix-blend-mode: screen;
}

.page_header .img_box {
  position: relative;
}

.page_header .img_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/banbg.png") no-repeat center;
  left: 50%;
  top: -25%;
  bottom: 0;
  margin: auto 0;
  width: 634px;
  height: 100%;
  mix-blend-mode: screen;
  filter: blur(32.5px);
  z-index: -1;
}

.about {
  padding: 0px 0 60px;
}

.about .aboutbg {
  border-radius: 60px;
  background: linear-gradient(180deg, #000203 14.51%, #00090e 126.76%);
  box-shadow: 0px 10px 10px -5px rgb(255 0 0 / 0%),
    0px 20px 25px -5px rgb(0 0 0 / 0%), 0px 1px 2px 0px #525154 inset;
  padding: 50px 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.about .aboutbg::before {
  content: "";
  position: absolute;
  background: radial-gradient(
    98.75% 34.21% at 50% 50%,
    #fff 0%,
    rgba(0, 180, 255, 0.33) 42.19%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  height: 1400px;
  left: 0;
  right: 0;
  filter: blur(26px);
  border-radius: 60px;
  top: -20px;
  z-index: -1;
}

.about p {
  font-size: 18px;
  color: #afafaf;
  margin-bottom: 25px;
}

.about .shade {
  position: relative;
}

.about .shade::before {
  content: "";
  position: absolute;
  z-index: -1;
  filter: blur(10px);
  width: 98%;
  height: 100px;
  top: 85%;
  background: linear-gradient(45deg, #1d566f, #f1f7fa 50%, #013f59);
  border-radius: 110px;
  left: 0;
  right: 0;
  margin: auto;
}

.feature {
  padding: 60px 0;
}

.feature p,
.feature h5 {
  color: #afafaf;
}

.feature h5 {
  margin-bottom: 10px;
  padding-top: 30px;
  margin-bottom: 0;
  display: inline-block;
}

.feature .img_box {
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    182.35% 174.5% at 0% 7%,
    rgba(255, 255, 255, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    rgba(0, 0, 0, 0) 100%
  );
  margin-bottom: 25px;
}

.feature .img_box::before {
  content: "";
  position: absolute;
  background: #34393f;
  height: 1px;
  width: 90%;
  top: 85px;
  left: 0;
}

.feature .box {
  margin-top: 24px;
  position: relative;
}

.feature .col-lg-4:not(:last-child) .box::after {
  content: "";
  position: absolute;
  right: 8px;
  top: 73px;
  height: 21px;
  width: 2px;
  background: #3d3f3f;
}

.feature .box:hover .img_box::before {
  background: #fff;
  transition: 0.5s;
}

.feature .box:hover h5 {
  background: linear-gradient(90deg, #fff 47.83%, #002a4a 113.76%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  transition: 0.5s;
}

.platform {
  padding: 55px 0;
}

.platform .box {
  border-radius: 60px;
  background: linear-gradient(180deg, #000203 0%, #0e0f10 100%);
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.9),
    0px 20px 25px -5px rgba(0, 0, 0, 0.8), 0px 1px 2px 0px #525154 inset;
  padding: 35px;
  padding-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.25;
  transform: scale(0.88);
  transition: 0.8s;
  position: relative;
}

.platform .box::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: radial-gradient(
    33.51% 33.51% at 47.5% 51.82%,
    #fff 0%,
    #00a0e3 20.36%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: blur(25px);
  min-width: 90%;
  height: 90%;
  top: -40px;
  z-index: -1;
  opacity: 0;
  transition: 0.5s;
}

.platform .box .flx {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 30px;
  margin-bottom: 30px;
}

.platform .inbox {
  border-radius: 15px;
  background: linear-gradient(180deg, #000203 0%, #161819 100%);
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.9),
    0px 20px 25px -5px rgba(0, 0, 0, 0.8), 0px 1px 2px 0px #525154 inset;
  padding: 10px;
}

.platform .inbox h5 {
  font-weight: 400;
  color: #bcbcbc;
  font-size: 14px;
  margin-bottom: 10px;
}

.platform .inbox p {
  font-weight: 500;
  margin-bottom: 0;
  color: #fff;
}

.platform .box a {
  display: flex;
  align-items: center;
  color: #959494;
}

.platform .slick-current .box {
  opacity: 1;
  transform: scale(1);
  transition: 0.8s;
  /* padding-top: 60px;
 padding-bottom: 45px; */
}

.platform .slick-current .box::before {
  opacity: 1;
  transition: 0.5s;
}

.platform .slick-slider {
  max-width: 90%;
  margin: 0 auto;
}

.platform .slick-prev,
.platform .slick-next {
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 46px;
  height: 46px;
  border-radius: 10px;
  top: unset;
  bottom: -100px;
  background: radial-gradient(
    196.03% 187.59% at 100% 6.52%,
    rgba(255, 255, 255, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    rgba(0, 0, 0, 0) 100%
  );
}

.slick-next:hover,
.slick-next:focus {
  background: radial-gradient(
    196.03% 187.59% at 100% 6.52%,
    rgba(255, 255, 255, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
}

.slick-prev:hover,
.slick-prev:focus {
  background: radial-gradient(
    270.03% 187.59% at 100% 6.52%,
    rgba(255, 255, 255, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
}

.platform .slick-prev {
  left: -65px;
}

.platform .slick-next {
  right: -65px;
  background: radial-gradient(
    182.35% 174.5% at 0% 7%,
    rgba(255, 255, 255, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    rgba(0, 0, 0, 0) 100%
  );
}

.platform .slick-prev:before,
.platform .slick-next:before {
  background: url("./assets/images/left.png") no-repeat center;
  background-color: transparent;
  font-size: 0;
  width: 8px;
  height: 15px;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.platform .slick-next:before {
  transform: rotate(180deg);
}

.roadmap {
  padding: 125px 0 0;
  padding: 50px 0;
  /* background: url("./assets/images/roadmap_bg1.png") no-repeat center; */
}

.roadmap ul {
  list-style-type: none;
  margin: 0 auto;
  width: fit-content;
  font-size: 18px;
}

.roadmap li {
  position: relative;
  margin-bottom: 10px;
}

.roadmap li::before {
  content: "";
  position: absolute;
  background: url("./assets/images/dot.png") no-repeat center;
  background-size: contain;
  left: -30px;
  top: 5px;
  width: 18px;
  height: 18px;
}

.roadmap .box {
  padding-bottom: 20px;
  border-bottom: 1px solid #34393f;
  border: 0 !important;
  position: relative;
  width: 310px;
}
/* .roadmap .box::before{
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  left: 0;
  height: 100%;
  bottom: 0;
  z-index: -1;
  background: #34393f;
  margin: 0 auto;
} */
.roadmap .box::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  bottom: -9px;
  background: radial-gradient(#00a0e3 37%, #00a0e32e 40%) no-repeat;
  height: 18px;
  width: 18px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 1;
}
.roadmap .inbox {
  margin: 0 10px;
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #00090e;
  background: linear-gradient(180deg, #000203 14.51%, #00090e 126.76%);
  border: 1px solid #181a1d;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  padding: 20px;
  padding-left: 130px;
  border-radius: 20px;
  background: linear-gradient(#000203, #000203) padding-box,
    linear-gradient(110deg, white -150%, transparent 55%, white 136%) border-box;
  border: 1px solid transparent;
}
.roadmap .inbox:hover {
  transition: 0.5s;
  background: linear-gradient(#000203, #000203) padding-box,
    linear-gradient(90deg, white -150%, transparent 55%, white 136%) border-box;
}
.roadmap .inbox::after {
  content: "";
  position: absolute;
  background: url("./assets/images/boxbg.png") no-repeat center;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
}
.roadmap .box span {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  text-transform: capitalize;
}
.roadmap .box p {
  margin: 0px;
  color: #fff;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 400;
}
.roadmap .box .title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.6px;
  text-transform: capitalize;
  background: linear-gradient(90deg, #fff 3.16%, #00a0e3 104.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
}
.roadmap .slick-list {
  height: 492px;
  position: relative;
  /* padding: 0 380px; */
}
.roadmap .slick-list::before {
  content: "";
  position: absolute;
  background: #34393f;
  width: 100%;
  height: 1px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.roadmap .slick-slide:nth-last-child(1) .box {
  width: unset;
}
.roadmap .slick-slide,
.roadmap .slick-slide > div,
.roadmap .slick-slide:focus-within {
  /* margin: 0 -40px; */
  outline: 0 !important;
  box-shadow: none !important;
}
.roadmap .slick-current {
  /* margin-left: 110px ; */
}
.roadmap .slick-current ~ .slick-slide {
  /* margin-left: -70px; */
}
.roadmap .slick-slide:nth-child(even) {
  transform: translateY(245px);
}
.roadmap .slick-slide:nth-child(even) .box {
  padding: 0;
  padding-top: 20px;
  border: 0;
  border-top: 1px solid #34393f;
}
.roadmap .slick-slide:nth-child(even) .box::after {
  bottom: unset;
  top: -9px;
}
/* .roadmap .slick-prev,.roadmap .slick-next{
  border-radius: 50%;
  background: transparent !important;
} */
.roadmap .slick-prev:hover,
.roadmap .slick-next:hover {
  background: transparent !important;
}
.roadmap .slick-prev::before,
.roadmap .slick-next::before {
  font-size: 0px;
  color: #36393a;
  background: url("./assets/images/left.png") no-repeat center;
  width: 8px;
  height: 12px;
  display: block;
  background-size: contain;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.roadmap .slick-next::before {
  transform: rotate(180deg);
}

.roadmap .slick-prev,
.roadmap .slick-next {
  background: radial-gradient(
    196.03% 187.59% at 100% 6.52%,
    hsla(0, 0%, 100%, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    transparent 100%
  );
  border-radius: 10px;
  bottom: -115px;
  height: 46px;
  left: 0;
  margin: 0 auto;
  right: 0;
  top: auto;
  width: 46px;
}
.roadmap .slick-prev {
  left: -65px;
}
.roadmap .slick-prev:focus,
.roadmap .slick-prev:hover {
  background: radial-gradient(
    270.03% 187.59% at 100% 6.52%,
    hsla(0, 0%, 100%, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    transparent 100%
  ) !important;
}
.roadmap .slick-next {
  background: radial-gradient(
    182.35% 174.5% at 0 7%,
    hsla(0, 0%, 100%, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    transparent 100%
  );
  right: -65px;
}
.roadmap .slick-next:focus,
.roadmap .slick-next:hover {
  background: radial-gradient(
    196.03% 187.59% at 100% 6.52%,
    hsla(0, 0%, 100%, 0.41) 0.56%,
    rgba(0, 0, 0, 0.15) 58.56%,
    transparent 100%
  ) !important;
}

.faq {
  padding: 0px 0 50px;
  padding: 50px 0;
  margin-top: -200px;
  margin-top: 60px !important;
}

.faq .accordion {
  max-width: 85%;
  margin: 0 auto;
}

.faq .accordion-item {
  box-shadow: none !important;
  border: 0;
  border-bottom: 1px solid #0e0f11;
  background: transparent;
  border-radius: 0;
  padding: 30px 0;
  position: relative;
}

.faq .accordion-item:nth-last-child(1) {
  border: 0;
}

.faq .accordion-item:not(:last-child)::before {
  content: "";
  border-radius: 2px;
  background: #3d3f3f;
  height: 3px;
  width: 21px;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.faq .accordion-item button {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  box-shadow: none !important;
  padding: 0;
}

.faq .para {
  color: #fff;
  margin-bottom: 0;
}

.faq .accordion-body {
  padding: 15px 0;
}

.faq .accordion-button:not(.collapsed)::after,
.faq .accordion-button.collapsed::after {
  background: url("./assets/images/down_arw.png") no-repeat center;
  width: 23px;
  height: 45px;
  transition: 0.5s;
}

.token {
  position: relative;
  padding: 45px 0 90px;
}

.token::before {
  content: "";
  position: relative;
  background: url("./assets/images/tokenbg.png") no-repeat center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -400px;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
  background-size: 100% 100%;
  z-index: -1;
}

.token .outbox {
  position: relative;
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
}

.token .outbox::before {
  content: "";
  position: absolute;
  box-shadow: 0px 102px 100px -99px #00a0e3;
  width: calc(100% - 6px);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 2px;
  left: 0px;
  right: 0;
  margin: auto;
  border-radius: 50%;
}

.token .outbox::after {
  content: "";
  position: absolute;
  background: radial-gradient(
    109.74% 102.83% at 48.77% -22.37%,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(0, 160, 227, 0.04) 42.19%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: blur(3px);
  width: 100%;
  height: 309px;
  background: url("./assets/images/circle_shade.png") no-repeat center;
  bottom: -310px;
}

.token .box {
  position: relative;
  z-index: 1;
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  overflow: hidden;
}

.token .blw_circle {
  position: absolute;
  top: 53%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.token p {
  color: #00a0e3;
  margin-bottom: 0;
}

.token .inbox {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.03) inset;
  padding: 10px;
  max-width: 55%;
  margin: 0 auto;
}

.token .inbox_bg {
  border-radius: 21px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: radial-gradient(
      70.71% 70.71% at 50% 50%,
      rgba(168, 127, 255, 0.04) 0%,
      rgba(168, 127, 255, 0) 100%
    ),
    #000d12;
  backdrop-filter: blur(17px);
  padding: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}

.token .inbox h5 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
}

.token .inbox_bg > div {
  position: relative;
}

.token .inbox_bg > div:not(:last-child)::before {
  content: "";
  position: absolute;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.24);
  width: 2px;
  height: 90%;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.token .inbox .center {
  width: fit-content;
  margin: 0 auto;
}

.token .white {
  color: #fff;
}

.token video {
  position: absolute;
  bottom: 0;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.token .mob_viewul {
  margin: 0 auto 25px;
  width: fit-content;
  text-align: center;
  padding: 0;
  list-style-type: none;
}

.wrapper {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.pdf {
  margin-left: 10px;
}

.scanner {
  -webkit-clip-path: polygon(
    0 0,
    93.3673469388% 0,
    93.3673469388% 25.1275510204%,
    50% 50.1275510204%,
    0 50.1275510204%
  );
  clip-path: polygon(
    0 0,
    93.3673469388% 0,
    93.3673469388% 25.1275510204%,
    50% 50.1275510204%,
    0 50.1275510204%
  );
  height: 900px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-201deg);
  width: 910px;
  animation: researchRadarRotate 10s infinite linear;
  right: -2px;
  margin: auto;
}

@keyframes researchRadarRotate {
  0% {
    transform: rotate(-201deg);
  }

  100% {
    transform: rotate(159deg);
  }
}

.scanner::before {
  content: "";
  background: linear-gradient(
    267deg,
    rgb(7 64 90) 13.23%,
    rgba(3, 0, 20, 0) 50%
  );
  border-radius: 50%;
  display: block;
  height: 100%;
  width: 100%;
}

.footer {
  background: url("./assets/images/footer_bg.png") no-repeat center;
  background-size: cover;
  padding: 35px 0;
}

ul.footer_social_links {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
}

ul.footer_social_links li a {
  background: #3d3f3f;
  min-width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 12px;
  color: #030704;
  text-decoration: none !important;
}

ul.footer_social_links li a:hover {
  background: #fff;
}

.footer p,
.footer p a {
  text-decoration: none;
  font-size: 14px;
  color: #898989;
  margin-bottom: 0;
  text-align: center;
}

.page_box {
  min-height: 100vh;
  padding-bottom: 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.page_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/banshade.png") no-repeat center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 190%;
  background-size: cover;
  z-index: -1;
}

ul.dropdown-menu.walletdrop {
  background: radial-gradient(
        112.34% 107.5% at 48.33% 53.85%,
        #3f7639 0.56%,
        #0e3142 58.56%,
        #0e3142 100%
      )
      padding-box,
    linear-gradient(to right, #ffffff, #000000, #4e4e4e) border-box;
  border: 1px solid #fff0;
}

ul.dropdown-menu.walletdrop li a {
  color: #fff;
  font-size: 14px;
}

ul.dropdown-menu.walletdrop li a:hover {
  background: #094162;
}

ul.dropdown-menu.walletdrop li a.active::after,
ul.dropdown-menu.walletdrop li a:hover::after {
  display: none;
}

.buytoken {
  padding-top: 150px;
  padding-bottom: 0px;
}

.buytoken .buytokenbg {
  border-radius: 20px;
  background: linear-gradient(180deg, #000203 14.51%, #00090e 126.76%);
  box-shadow: 0px 10px 10px -5px rgb(255 0 0 / 0%),
    0px 20px 25px -5px rgb(0 0 0 / 0%), 0px 1px 2px 0px #1d566f inset;
  padding: 50px 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.buytoken .buytokenbg::before {
  content: "";
  position: absolute;
  background: radial-gradient(
    98.75% 34.21% at 50% 50%,
    #fff 0%,
    rgba(0, 180, 255, 0.33) 42.19%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  filter: blur(26px);
  border-radius: 60px;
  top: 50%;
  z-index: -1;
}

.buytoken .form-control,
.buytoken .input-group-text {
  background: transparent;
  box-shadow: none !important;
  padding: 10px;
  border-color: #1d566f !important;
  color: #fff;
}

.buytoken .form-control {
  background: #060608;
  color: #fff;
  font-size: 15px;
  border: 1px solid #1a1d21;
  height: 52px;
  /* border-right: 0; */
}

.buytoken .form-control::placeholder {
  color: #fff;
  font-size: 15px;
}

.buytoken .form-control:focus {
  box-shadow: none;
}

.table_sec .h5tag {
  font-size: 28px;
  background: var(
    --Heading,
    linear-gradient(90deg, #fff 47.83%, #002a4a 104.53%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.rdt_Table {
  color: #fff;
}

.rdt_TableCol {
  color: #fff;
  font-size: 16px;
}

.table_sec {
  padding-top: 60px;
}

.rdt_TableHeadRow {
  background: #000203 !important;
  border-color: #000d12 !important;
}

.rdt_TableRow {
  background: #000 !important;
  color: #fff;
}

.rdt_TableCell {
  color: #888282;
}

.rdt_Pagination,
.rdt_Table > div,
.rdt_TableCol {
  background: #000 !important;
  color: #fff !important;
}

.rdt_Pagination select {
  outline: 0 !important;
}

.rdt_Pagination button {
  fill: #fff;
}

.rdt_Pagination button:disabled {
  fill: rgb(255 255 255 / 26%);
}


.litepaper .page_header .img_box::before{
 display: none;
}

@media (max-width: 1399px) {
  .roadmap .inbox {
    padding-left: 95px;
  }
}
@media (max-width: 1199px) {
  .roadmap .box {
    width: 290px;
  }
  .platform .box {
    padding: 15px;
  }

  .platform .box .flx {
    gap: 10px;
  }

  .offcanvas {
    background: #000;
  }

  .offcanvas .links {
    flex-direction: column;
    background: transparent;
    border: 0;
  }

  .offcanvas .links li {
    margin: 10px 0;
  }

  .navbar_right {
    flex-direction: column;
  }

  .page_header::after {
    width: 50%;
  }

  .page_header h1 {
    font-size: 118px;
  }

  .roadmap {
    background-size: 75%;
  }
}

@media (max-width: 991px) {
  .head {
    max-width: 100%;
  }

  .h2tag {
    font-size: 38px;
  }

  .page_header .img_box,
  .page_header {
    text-align: center;
  }

  .page_header::before {
    background-size: cover;
  }

  .page_header h1 {
    font-size: 78px;
  }

  .page_header p {
    font-size: 20px;
  }

  .page_header .img_box::before {
    width: 50%;
  }

  .platform .box .flx {
    max-width: 70%;
  }

  .about .aboutbg {
    padding: 50px;
  }

  .feature .col-lg-4:not(:last-child) .box::after {
    display: none;
  }

  .faq {
    margin-top: 0;
    padding-top: 100px;
  }

  .faq .accordion {
    max-width: 100%;
  }

  .footer {
    text-align: center;
  }

  .footer p {
    margin: 20px 0;
  }

  ul.footer_social_links {
    justify-content: center;
  }

  ul.footer_social_links li a {
    margin: 0 8px;
  }

  .about .aboutbg::before {
    background: radial-gradient(
      98.75% 34.21% at 50% 50%,
      #3a8aac 0%,
      rgb(0 180 255 / 33%) 42.19%,
      rgb(66 52 52 / 0%) 100%
    );
  }

  .token .box,
  .token .outbox {
    border-radius: 0;
    width: 90%;
  }

  .token video {
    display: none;
  }

  .token .blw_circle {
    position: unset;
  }

  .token img,
  .token .outbox::before,
  .token::before,
  .token .outbox::after {
    display: none;
  }

  .token .inbox {
    max-width: 100%;
  }

  .token .inbox_bg {
    display: block;
    text-align: center;
  }

  .token .inbox_bg > div {
    margin: 0 0 10px !important;
    padding: 0 !important;
  }

  .token .inbox_bg > div:nth-last-child(1) {
    margin: 0 !important;
  }

  .wrapper,
  .token .inbox_bg > div:not(:last-child)::before {
    display: none;
  }
}

@media (max-width: 767px) {
  .h2tag,
  .feature .box:hover h5,
  .page_header h1 {
    color: #fff;
    background: none;
    background-clip: unset;
    -webkit-text-fill-color: unset;
  }
  .roadmap .box {
    width: unset;
    border: 0 !important;
    padding: 0 !important;
  }
  .roadmap .box::after,
  .roadmap .box::before {
    display: none;
  }
  .roadmap .inbox {
    padding-left: 125px;
  }
  .roadmap .inbox::after {
    background-position: left;
  }
  .roadmap .slick-list {
    height: 230px;
  }
  .roadmap .slick-list::before {
    display: none;
  }
  .roadmap .slick-slide:nth-child(even) {
    transform: translateY(0px);
  }
  .roadmap .slick-prev,
  .roadmap .slick-next {
    bottom: -95px;
  }

  .buytoken .buytokenbg {
    padding: 30px 20px;
  }

  .faq .accordion-item button {
    font-size: 17px;
  }

  .roadmap ul {
    font-size: 16px;
  }

  .feature,
  .token {
    padding: 35px 0;
  }

  .feature .box {
    text-align: center;
  }

  .feature .img_box {
    margin: 0 auto 10px;
  }

  .feature .img_box::before {
    right: 0;
    margin: auto;
  }

  .about .aboutbg {
    text-align: center;
  }

  .roadmap {
    padding: 95px 0 0;
  }
}

@media (max-width: 575px) {
  .h2tag {
    font-size: 32px;
  }

  .page_header h1 {
    font-size: 68px;
  }

  .page_header .img_box::before {
    height: 50%;
  }

  .platform .box {
    border-radius: 30px;
  }

  .platform .box::before {
    top: -70px;
    width: 70%;
    height: 70%;
  }

  .platform .inbox {
    padding: 15px 10px;
  }

  .platform .box .flx {
    max-width: 95%;
    display: block;
  }

  .platform .inbox {
    margin-top: 15px;
  }

  .about .aboutbg {
    padding: 25px;
    border-radius: 30px;
  }
}

/*snow css animation  */

.snow {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #505a62;
  border-radius: 50%;
  opacity: 1 !important;
}

.snow:nth-child(1) {
  opacity: 0.9549;
  transform: translate(85.1887vw, -10px) scale(0.3923);
  animation: fall-1 84s -94s linear infinite;
}

@keyframes fall-1 {
  62.604% {
    transform: translate(84.6588vw, 62.604vh) scale(0.3923);
    top: 62.604vh;
  }

  to {
    transform: translate(84.92375vw, 100vh) scale(0.3923);
    top: 100%;
  }
}

.snow:nth-child(2) {
  opacity: 0.1627;
  transform: translate(49.4953vw, -10px) scale(0.7599);
  animation: fall-2 94s -49s linear infinite;
}

@keyframes fall-2 {
  68.667% {
    transform: translate(58.7375vw, 68.667vh) scale(0.7599);
    top: 68.667vh;
  }

  to {
    transform: translate(54.1164vw, 100vh) scale(0.7599);
    top: 100%;
  }
}

.snow:nth-child(3) {
  opacity: 0.0615;
  transform: translate(77.6778vw, -10px) scale(0.8394);
  animation: fall-3 127s -25s linear infinite;
}

@keyframes fall-3 {
  56.931% {
    transform: translate(69.7312vw, 56.931vh) scale(0.8394);
    top: 56.931vh;
  }

  to {
    transform: translate(73.7045vw, 100vh) scale(0.8394);
    top: 100%;
  }
}

.snow:nth-child(4) {
  opacity: 0.9731;
  transform: translate(70.5756vw, -10px) scale(0.0044);
  animation: fall-4 122s -75s linear infinite;
}

@keyframes fall-4 {
  67.125% {
    transform: translate(69.8483vw, 67.125vh) scale(0.0044);
    top: 67.125vh;
  }

  to {
    transform: translate(70.21195vw, 100vh) scale(0.0044);
    top: 100%;
  }
}

.snow:nth-child(5) {
  opacity: 0.6657;
  transform: translate(15.0825vw, -10px) scale(0.3035);
  animation: fall-5 126s -78s linear infinite;
}

@keyframes fall-5 {
  76.177% {
    transform: translate(20.1225vw, 76.177vh) scale(0.3035);
    top: 76.177vh;
  }

  to {
    transform: translate(17.6025vw, 100vh) scale(0.3035);
    top: 100%;
  }
}

.snow:nth-child(6) {
  opacity: 0.5599;
  transform: translate(39.9802vw, -10px) scale(0.2775);
  animation: fall-6 93s -53s linear infinite;
}

@keyframes fall-6 {
  67.293% {
    transform: translate(33.011vw, 67.293vh) scale(0.2775);
    top: 67.293vh;
  }

  to {
    transform: translate(36.4956vw, 100vh) scale(0.2775);
    top: 100%;
  }
}

.snow:nth-child(7) {
  opacity: 0.551;
  transform: translate(80.7169vw, -10px) scale(0.6411);
  animation: fall-7 80s -3s linear infinite;
}

@keyframes fall-7 {
  63.436% {
    transform: translate(81.5557vw, 63.436vh) scale(0.6411);
    top: 63.436vh;
  }

  to {
    transform: translate(81.1363vw, 100vh) scale(0.6411);
    top: 100%;
  }
}

.snow:nth-child(8) {
  opacity: 0.7219;
  transform: translate(30.1988vw, -10px) scale(0.3178);
  animation: fall-8 100s -90s linear infinite;
}

@keyframes fall-8 {
  55.526% {
    transform: translate(20.593vw, 55.526vh) scale(0.3178);
    top: 55.526vh;
  }

  to {
    transform: translate(25.3959vw, 100vh) scale(0.3178);
    top: 100%;
  }
}

.snow:nth-child(9) {
  opacity: 0.5463;
  transform: translate(1.1765vw, -10px) scale(0.2803);
  animation: fall-9 113s -64s linear infinite;
}

@keyframes fall-9 {
  45.059% {
    transform: translate(9.9187vw, 45.059vh) scale(0.2803);
    top: 45.059vh;
  }

  to {
    transform: translate(5.5476vw, 100vh) scale(0.2803);
    top: 100%;
  }
}

.snow:nth-child(10) {
  opacity: 0.7387;
  transform: translate(5.1352vw, -10px) scale(0.8105);
  animation: fall-10 101s -31s linear infinite;
}

@keyframes fall-10 {
  47.673% {
    transform: translate(3.9153vw, 47.673vh) scale(0.8105);
    top: 47.673vh;
  }

  to {
    transform: translate(4.52525vw, 100vh) scale(0.8105);
    top: 100%;
  }
}

.snow:nth-child(11) {
  opacity: 0.2916;
  transform: translate(9.5792vw, -10px) scale(0.1205);
  animation: fall-11 96s -89s linear infinite;
}

@keyframes fall-11 {
  46.798% {
    transform: translate(14.324vw, 46.798vh) scale(0.1205);
    top: 46.798vh;
  }

  to {
    transform: translate(11.9516vw, 100vh) scale(0.1205);
    top: 100%;
  }
}

.snow:nth-child(12) {
  opacity: 0.0393;
  transform: translate(25.2086vw, -10px) scale(0.7675);
  animation: fall-12 117s -58s linear infinite;
}

@keyframes fall-12 {
  60.927% {
    transform: translate(19.424vw, 60.927vh) scale(0.7675);
    top: 60.927vh;
  }

  to {
    transform: translate(22.3163vw, 100vh) scale(0.7675);
    top: 100%;
  }
}

.snow:nth-child(13) {
  opacity: 0.6126;
  transform: translate(14.6209vw, -10px) scale(0.9855);
  animation: fall-13 118s -3s linear infinite;
}

@keyframes fall-13 {
  35.104% {
    transform: translate(22.7804vw, 35.104vh) scale(0.9855);
    top: 35.104vh;
  }

  to {
    transform: translate(18.70065vw, 100vh) scale(0.9855);
    top: 100%;
  }
}

.snow:nth-child(14) {
  opacity: 0.0951;
  transform: translate(98.4575vw, -10px) scale(0.9121);
  animation: fall-14 103s -40s linear infinite;
}

@keyframes fall-14 {
  34.867% {
    transform: translate(108.3247vw, 34.867vh) scale(0.9121);
    top: 34.867vh;
  }

  to {
    transform: translate(103.3911vw, 100vh) scale(0.9121);
    top: 100%;
  }
}

.snow:nth-child(15) {
  opacity: 0.1347;
  transform: translate(12.5016vw, -10px) scale(0.2849);
  animation: fall-15 81s -75s linear infinite;
}

@keyframes fall-15 {
  50.695% {
    transform: translate(13.2409vw, 50.695vh) scale(0.2849);
    top: 50.695vh;
  }

  to {
    transform: translate(12.87125vw, 100vh) scale(0.2849);
    top: 100%;
  }
}

.snow:nth-child(16) {
  opacity: 0.3652;
  transform: translate(43.9368vw, -10px) scale(0.7021);
  animation: fall-16 98s -55s linear infinite;
}

@keyframes fall-16 {
  45.33% {
    transform: translate(40.7135vw, 45.33vh) scale(0.7021);
    top: 45.33vh;
  }

  to {
    transform: translate(42.32515vw, 100vh) scale(0.7021);
    top: 100%;
  }
}

.snow:nth-child(17) {
  opacity: 0.4964;
  transform: translate(19.6371vw, -10px) scale(0.3238);
  animation: fall-17 82s -2s linear infinite;
}

@keyframes fall-17 {
  62.914% {
    transform: translate(28.6202vw, 62.914vh) scale(0.3238);
    top: 62.914vh;
  }

  to {
    transform: translate(24.12865vw, 100vh) scale(0.3238);
    top: 100%;
  }
}

.snow:nth-child(18) {
  opacity: 0.8315;
  transform: translate(28.778vw, -10px) scale(0.1302);
  animation: fall-18 90s -74s linear infinite;
}

@keyframes fall-18 {
  72.87% {
    transform: translate(30.6986vw, 72.87vh) scale(0.1302);
    top: 72.87vh;
  }

  to {
    transform: translate(29.7383vw, 100vh) scale(0.1302);
    top: 100%;
  }
}

.snow:nth-child(19) {
  opacity: 0.1494;
  transform: translate(18.8417vw, -10px) scale(0.164);
  animation: fall-19 87s -70s linear infinite;
}

@keyframes fall-19 {
  46.755% {
    transform: translate(12.5484vw, 46.755vh) scale(0.164);
    top: 46.755vh;
  }

  to {
    transform: translate(15.69505vw, 100vh) scale(0.164);
    top: 100%;
  }
}

.snow:nth-child(20) {
  opacity: 0.421;
  transform: translate(84.4645vw, -10px) scale(0.3005);
  animation: fall-20 118s -20s linear infinite;
}

@keyframes fall-20 {
  32.276% {
    transform: translate(85.1651vw, 32.276vh) scale(0.3005);
    top: 32.276vh;
  }

  to {
    transform: translate(84.8148vw, 100vh) scale(0.3005);
    top: 100%;
  }
}

.snow:nth-child(21) {
  opacity: 0.5043;
  transform: translate(12.7011vw, -10px) scale(0.4394);
  animation: fall-21 128s -65s linear infinite;
}

@keyframes fall-21 {
  41.115% {
    transform: translate(17.3705vw, 41.115vh) scale(0.4394);
    top: 41.115vh;
  }

  to {
    transform: translate(15.0358vw, 100vh) scale(0.4394);
    top: 100%;
  }
}

.snow:nth-child(22) {
  opacity: 0.1844;
  transform: translate(22.2626vw, -10px) scale(0.168);
  animation: fall-22 119s -88s linear infinite;
}

@keyframes fall-22 {
  68.585% {
    transform: translate(23.03vw, 68.585vh) scale(0.168);
    top: 68.585vh;
  }

  to {
    transform: translate(22.6463vw, 100vh) scale(0.168);
    top: 100%;
  }
}

.snow:nth-child(23) {
  opacity: 0.7907;
  transform: translate(80.1572vw, -10px) scale(0.51);
  animation: fall-23 129s -55s linear infinite;
}

@keyframes fall-23 {
  45.954% {
    transform: translate(77.6988vw, 45.954vh) scale(0.51);
    top: 45.954vh;
  }

  to {
    transform: translate(78.928vw, 100vh) scale(0.51);
    top: 100%;
  }
}

.snow:nth-child(24) {
  opacity: 0.9826;
  transform: translate(41.2543vw, -10px) scale(0.503);
  animation: fall-24 108s -34s linear infinite;
}

@keyframes fall-24 {
  59.197% {
    transform: translate(39.9798vw, 59.197vh) scale(0.503);
    top: 59.197vh;
  }

  to {
    transform: translate(40.61705vw, 100vh) scale(0.503);
    top: 100%;
  }
}

.snow:nth-child(25) {
  opacity: 0.3599;
  transform: translate(78.3755vw, -10px) scale(0.9853);
  animation: fall-25 116s -55s linear infinite;
}

@keyframes fall-25 {
  50.818% {
    transform: translate(69.4892vw, 50.818vh) scale(0.9853);
    top: 50.818vh;
  }

  to {
    transform: translate(73.93235vw, 100vh) scale(0.9853);
    top: 100%;
  }
}

.snow:nth-child(26) {
  opacity: 0.7485;
  transform: translate(5.5524vw, -10px) scale(0.2212);
  animation: fall-26 130s -61s linear infinite;
}

@keyframes fall-26 {
  36.992% {
    transform: translate(0.6598vw, 36.992vh) scale(0.2212);
    top: 36.992vh;
  }

  to {
    transform: translate(3.1061vw, 100vh) scale(0.2212);
    top: 100%;
  }
}

.snow:nth-child(27) {
  opacity: 0.1771;
  transform: translate(33.784vw, -10px) scale(0.7064);
  animation: fall-27 127s -55s linear infinite;
}

@keyframes fall-27 {
  67.801% {
    transform: translate(37.3473vw, 67.801vh) scale(0.7064);
    top: 67.801vh;
  }

  to {
    transform: translate(35.56565vw, 100vh) scale(0.7064);
    top: 100%;
  }
}

.snow:nth-child(28) {
  opacity: 0.7337;
  transform: translate(71.7054vw, -10px) scale(0.5932);
  animation: fall-28 129s -79s linear infinite;
}

@keyframes fall-28 {
  64.865% {
    transform: translate(66.2004vw, 64.865vh) scale(0.5932);
    top: 64.865vh;
  }

  to {
    transform: translate(68.9529vw, 100vh) scale(0.5932);
    top: 100%;
  }
}

.snow:nth-child(29) {
  opacity: 0.5537;
  transform: translate(82.5628vw, -10px) scale(0.495);
  animation: fall-29 120s -96s linear infinite;
}

@keyframes fall-29 {
  34.688% {
    transform: translate(73.1134vw, 34.688vh) scale(0.495);
    top: 34.688vh;
  }

  to {
    transform: translate(77.8381vw, 100vh) scale(0.495);
    top: 100%;
  }
}

.snow:nth-child(30) {
  opacity: 0.366;
  transform: translate(91.3477vw, -10px) scale(0.5015);
  animation: fall-30 80s -97s linear infinite;
}

@keyframes fall-30 {
  59.147% {
    transform: translate(94.1507vw, 59.147vh) scale(0.5015);
    top: 59.147vh;
  }

  to {
    transform: translate(92.7492vw, 100vh) scale(0.5015);
    top: 100%;
  }
}

.snow:nth-child(31) {
  opacity: 0.2316;
  transform: translate(89.2702vw, -10px) scale(0.9204);
  animation: fall-31 116s -17s linear infinite;
}

@keyframes fall-31 {
  64.301% {
    transform: translate(95.6248vw, 64.301vh) scale(0.9204);
    top: 64.301vh;
  }

  to {
    transform: translate(92.4475vw, 100vh) scale(0.9204);
    top: 100%;
  }
}

.snow:nth-child(32) {
  opacity: 0.3708;
  transform: translate(33.4694vw, -10px) scale(0.6589);
  animation: fall-32 80s -67s linear infinite;
}

@keyframes fall-32 {
  78.948% {
    transform: translate(24.4383vw, 78.948vh) scale(0.6589);
    top: 78.948vh;
  }

  to {
    transform: translate(28.95385vw, 100vh) scale(0.6589);
    top: 100%;
  }
}

.snow:nth-child(33) {
  opacity: 0.9399;
  transform: translate(84.7073vw, -10px) scale(0.98);
  animation: fall-33 129s -61s linear infinite;
}

@keyframes fall-33 {
  62.371% {
    transform: translate(78.5041vw, 62.371vh) scale(0.98);
    top: 62.371vh;
  }

  to {
    transform: translate(81.6057vw, 100vh) scale(0.98);
    top: 100%;
  }
}

.snow:nth-child(34) {
  opacity: 0.2114;
  transform: translate(69.6831vw, -10px) scale(0.0487);
  animation: fall-34 91s -81s linear infinite;
}

@keyframes fall-34 {
  32.598% {
    transform: translate(74.9311vw, 32.598vh) scale(0.0487);
    top: 32.598vh;
  }

  to {
    transform: translate(72.3071vw, 100vh) scale(0.0487);
    top: 100%;
  }
}

.snow:nth-child(35) {
  opacity: 0.6376;
  transform: translate(72.4654vw, -10px) scale(0.9103);
  animation: fall-35 104s -27s linear infinite;
}

@keyframes fall-35 {
  53.437% {
    transform: translate(70.9638vw, 53.437vh) scale(0.9103);
    top: 53.437vh;
  }

  to {
    transform: translate(71.7146vw, 100vh) scale(0.9103);
    top: 100%;
  }
}

.snow:nth-child(36) {
  opacity: 0.0317;
  transform: translate(79.1999vw, -10px) scale(0.3181);
  animation: fall-36 112s -6s linear infinite;
}

@keyframes fall-36 {
  51.175% {
    transform: translate(82.1478vw, 51.175vh) scale(0.3181);
    top: 51.175vh;
  }

  to {
    transform: translate(80.67385vw, 100vh) scale(0.3181);
    top: 100%;
  }
}

.snow:nth-child(37) {
  opacity: 0.3617;
  transform: translate(91.2533vw, -10px) scale(0.7823);
  animation: fall-37 88s -34s linear infinite;
}

@keyframes fall-37 {
  79.564% {
    transform: translate(85.5082vw, 79.564vh) scale(0.7823);
    top: 79.564vh;
  }

  to {
    transform: translate(88.38075vw, 100vh) scale(0.7823);
    top: 100%;
  }
}

.snow:nth-child(38) {
  opacity: 0.611;
  transform: translate(58.6995vw, -10px) scale(0.7309);
  animation: fall-38 93s -30s linear infinite;
}

@keyframes fall-38 {
  70.539% {
    transform: translate(60.2937vw, 70.539vh) scale(0.7309);
    top: 70.539vh;
  }

  to {
    transform: translate(59.4966vw, 100vh) scale(0.7309);
    top: 100%;
  }
}

.snow:nth-child(39) {
  opacity: 0.7658;
  transform: translate(14.0645vw, -10px) scale(0.242);
  animation: fall-39 130s -95s linear infinite;
}

@keyframes fall-39 {
  54.412% {
    transform: translate(7.0761vw, 54.412vh) scale(0.242);
    top: 54.412vh;
  }

  to {
    transform: translate(10.5703vw, 100vh) scale(0.242);
    top: 100%;
  }
}

.snow:nth-child(40) {
  opacity: 0.931;
  transform: translate(6.7777vw, -10px) scale(0.9887);
  animation: fall-40 98s -100s linear infinite;
}

@keyframes fall-40 {
  67.239% {
    transform: translate(-2.7821vw, 67.239vh) scale(0.9887);
    top: 67.239vh;
  }

  to {
    transform: translate(1.9978vw, 100vh) scale(0.9887);
    top: 100%;
  }
}

.snow:nth-child(41) {
  opacity: 0.3824;
  transform: translate(86.9801vw, -10px) scale(0.8316);
  animation: fall-41 128s -69s linear infinite;
}

@keyframes fall-41 {
  73.225% {
    transform: translate(86.1755vw, 73.225vh) scale(0.8316);
    top: 73.225vh;
  }

  to {
    transform: translate(86.5778vw, 100vh) scale(0.8316);
    top: 100%;
  }
}

.snow:nth-child(42) {
  opacity: 0.4993;
  transform: translate(62.7095vw, -10px) scale(0.6449);
  animation: fall-42 124s -72s linear infinite;
}

@keyframes fall-42 {
  69.369% {
    transform: translate(58.2261vw, 69.369vh) scale(0.6449);
    top: 69.369vh;
  }

  to {
    transform: translate(60.4678vw, 100vh) scale(0.6449);
    top: 100%;
  }
}

.snow:nth-child(43) {
  opacity: 0.3464;
  transform: translate(52.8933vw, -10px) scale(0.3526);
  animation: fall-43 108s -16s linear infinite;
}

@keyframes fall-43 {
  65.074% {
    transform: translate(55.5823vw, 65.074vh) scale(0.3526);
    top: 65.074vh;
  }

  to {
    transform: translate(54.2378vw, 100vh) scale(0.3526);
    top: 100%;
  }
}

.snow:nth-child(44) {
  opacity: 0.1205;
  transform: translate(29.4462vw, -10px) scale(0.5135);
  animation: fall-44 98s -70s linear infinite;
}

@keyframes fall-44 {
  42.514% {
    transform: translate(25.9688vw, 42.514vh) scale(0.5135);
    top: 42.514vh;
  }

  to {
    transform: translate(27.7075vw, 100vh) scale(0.5135);
    top: 100%;
  }
}

.snow:nth-child(45) {
  opacity: 0.6852;
  transform: translate(55.3945vw, -10px) scale(0.6353);
  animation: fall-45 118s -2s linear infinite;
}

@keyframes fall-45 {
  56.918% {
    transform: translate(50.9154vw, 56.918vh) scale(0.6353);
    top: 56.918vh;
  }

  to {
    transform: translate(53.15495vw, 100vh) scale(0.6353);
    top: 100%;
  }
}

.snow:nth-child(46) {
  opacity: 0.2314;
  transform: translate(8.114vw, -10px) scale(0.8987);
  animation: fall-46 114s -69s linear infinite;
}

@keyframes fall-46 {
  64.668% {
    transform: translate(2.3751vw, 64.668vh) scale(0.8987);
    top: 64.668vh;
  }

  to {
    transform: translate(5.24455vw, 100vh) scale(0.8987);
    top: 100%;
  }
}

.snow:nth-child(47) {
  opacity: 0.4941;
  transform: translate(40.4615vw, -10px) scale(0.2939);
  animation: fall-47 110s -97s linear infinite;
}

@keyframes fall-47 {
  48.694% {
    transform: translate(32.047vw, 48.694vh) scale(0.2939);
    top: 48.694vh;
  }

  to {
    transform: translate(36.25425vw, 100vh) scale(0.2939);
    top: 100%;
  }
}

.snow:nth-child(48) {
  opacity: 0.1256;
  transform: translate(7.7209vw, -10px) scale(0.6986);
  animation: fall-48 105s -82s linear infinite;
}

@keyframes fall-48 {
  61.578% {
    transform: translate(6.4358vw, 61.578vh) scale(0.6986);
    top: 61.578vh;
  }

  to {
    transform: translate(7.07835vw, 100vh) scale(0.6986);
    top: 100%;
  }
}

.snow:nth-child(49) {
  opacity: 0.9381;
  transform: translate(11.0734vw, -10px) scale(0.9945);
  animation: fall-49 86s -14s linear infinite;
}

@keyframes fall-49 {
  66.879% {
    transform: translate(9.4941vw, 66.879vh) scale(0.9945);
    top: 66.879vh;
  }

  to {
    transform: translate(10.28375vw, 100vh) scale(0.9945);
    top: 100%;
  }
}

.snow:nth-child(50) {
  opacity: 0.3583;
  transform: translate(8.6615vw, -10px) scale(0.715);
  animation: fall-50 96s -83s linear infinite;
}

@keyframes fall-50 {
  68.272% {
    transform: translate(-0.5827vw, 68.272vh) scale(0.715);
    top: 68.272vh;
  }

  to {
    transform: translate(4.0394vw, 100vh) scale(0.715);
    top: 100%;
  }
}

.snow:nth-child(51) {
  opacity: 0.2644;
  transform: translate(43.7981vw, -10px) scale(0.1383);
  animation: fall-51 97s -67s linear infinite;
}

@keyframes fall-51 {
  44.494% {
    transform: translate(46.1917vw, 44.494vh) scale(0.1383);
    top: 44.494vh;
  }

  to {
    transform: translate(44.9949vw, 100vh) scale(0.1383);
    top: 100%;
  }
}

.snow:nth-child(52) {
  opacity: 0.6813;
  transform: translate(37.084vw, -10px) scale(0.9079);
  animation: fall-52 130s -93s linear infinite;
}

@keyframes fall-52 {
  47.137% {
    transform: translate(40.8976vw, 47.137vh) scale(0.9079);
    top: 47.137vh;
  }

  to {
    transform: translate(38.9908vw, 100vh) scale(0.9079);
    top: 100%;
  }
}

.snow:nth-child(53) {
  opacity: 0.5666;
  transform: translate(14.2144vw, -10px) scale(0.0897);
  animation: fall-53 83s -95s linear infinite;
}

@keyframes fall-53 {
  58.205% {
    transform: translate(6.2811vw, 58.205vh) scale(0.0897);
    top: 58.205vh;
  }

  to {
    transform: translate(10.24775vw, 100vh) scale(0.0897);
    top: 100%;
  }
}

.snow:nth-child(54) {
  opacity: 0.7576;
  transform: translate(55.6612vw, -10px) scale(0.1785);
  animation: fall-54 116s -90s linear infinite;
}

@keyframes fall-54 {
  54.267% {
    transform: translate(57.1241vw, 54.267vh) scale(0.1785);
    top: 54.267vh;
  }

  to {
    transform: translate(56.39265vw, 100vh) scale(0.1785);
    top: 100%;
  }
}

.snow:nth-child(55) {
  opacity: 0.0247;
  transform: translate(10.2928vw, -10px) scale(0.2081);
  animation: fall-55 95s -50s linear infinite;
}

@keyframes fall-55 {
  51.845% {
    transform: translate(2.2873vw, 51.845vh) scale(0.2081);
    top: 51.845vh;
  }

  to {
    transform: translate(6.29005vw, 100vh) scale(0.2081);
    top: 100%;
  }
}

.snow:nth-child(56) {
  opacity: 0.9117;
  transform: translate(10.491vw, -10px) scale(0.7477);
  animation: fall-56 113s -48s linear infinite;
}

@keyframes fall-56 {
  75.372% {
    transform: translate(19.7577vw, 75.372vh) scale(0.7477);
    top: 75.372vh;
  }

  to {
    transform: translate(15.12435vw, 100vh) scale(0.7477);
    top: 100%;
  }
}

.snow:nth-child(57) {
  opacity: 0.5548;
  transform: translate(21.9424vw, -10px) scale(0.4699);
  animation: fall-57 99s -93s linear infinite;
}

@keyframes fall-57 {
  42.275% {
    transform: translate(13.6414vw, 42.275vh) scale(0.4699);
    top: 42.275vh;
  }

  to {
    transform: translate(17.7919vw, 100vh) scale(0.4699);
    top: 100%;
  }
}

.snow:nth-child(58) {
  opacity: 0.9738;
  transform: translate(54.3247vw, -10px) scale(0.1775);
  animation: fall-58 108s -46s linear infinite;
}

@keyframes fall-58 {
  47.111% {
    transform: translate(45.1943vw, 47.111vh) scale(0.1775);
    top: 47.111vh;
  }

  to {
    transform: translate(49.7595vw, 100vh) scale(0.1775);
    top: 100%;
  }
}

.snow:nth-child(59) {
  opacity: 0.4749;
  transform: translate(63.5835vw, -10px) scale(0.1153);
  animation: fall-59 101s -42s linear infinite;
}

@keyframes fall-59 {
  59.031% {
    transform: translate(69.1063vw, 59.031vh) scale(0.1153);
    top: 59.031vh;
  }

  to {
    transform: translate(66.3449vw, 100vh) scale(0.1153);
    top: 100%;
  }
}

.snow:nth-child(60) {
  opacity: 0.4347;
  transform: translate(47.0478vw, -10px) scale(0.0248);
  animation: fall-60 127s -56s linear infinite;
}

@keyframes fall-60 {
  47.045% {
    transform: translate(48.5037vw, 47.045vh) scale(0.0248);
    top: 47.045vh;
  }

  to {
    transform: translate(47.77575vw, 100vh) scale(0.0248);
    top: 100%;
  }
}

.snow:nth-child(61) {
  opacity: 0.4644;
  transform: translate(62.3608vw, -10px) scale(0.1749);
  animation: fall-61 92s -67s linear infinite;
}

@keyframes fall-61 {
  62.393% {
    transform: translate(71.1234vw, 62.393vh) scale(0.1749);
    top: 62.393vh;
  }

  to {
    transform: translate(66.7421vw, 100vh) scale(0.1749);
    top: 100%;
  }
}

.snow:nth-child(62) {
  opacity: 0.8829;
  transform: translate(76.1127vw, -10px) scale(0.7519);
  animation: fall-62 95s -87s linear infinite;
}

@keyframes fall-62 {
  52.982% {
    transform: translate(79.8967vw, 52.982vh) scale(0.7519);
    top: 52.982vh;
  }

  to {
    transform: translate(78.0047vw, 100vh) scale(0.7519);
    top: 100%;
  }
}

.snow:nth-child(63) {
  opacity: 0.4304;
  transform: translate(60.7803vw, -10px) scale(0.1318);
  animation: fall-63 108s -84s linear infinite;
}

@keyframes fall-63 {
  48.588% {
    transform: translate(62.5969vw, 48.588vh) scale(0.1318);
    top: 48.588vh;
  }

  to {
    transform: translate(61.6886vw, 100vh) scale(0.1318);
    top: 100%;
  }
}

.snow:nth-child(64) {
  opacity: 0.3417;
  transform: translate(48.5111vw, -10px) scale(0.3606);
  animation: fall-64 127s -54s linear infinite;
}

@keyframes fall-64 {
  42.223% {
    transform: translate(49.1613vw, 42.223vh) scale(0.3606);
    top: 42.223vh;
  }

  to {
    transform: translate(48.8362vw, 100vh) scale(0.3606);
    top: 100%;
  }
}

.snow:nth-child(65) {
  opacity: 0.1475;
  transform: translate(81.8347vw, -10px) scale(0.6316);
  animation: fall-65 91s -53s linear infinite;
}

@keyframes fall-65 {
  52.095% {
    transform: translate(91.5032vw, 52.095vh) scale(0.6316);
    top: 52.095vh;
  }

  to {
    transform: translate(86.66895vw, 100vh) scale(0.6316);
    top: 100%;
  }
}

.snow:nth-child(66) {
  opacity: 0.9194;
  transform: translate(67.3623vw, -10px) scale(0.1491);
  animation: fall-66 87s -86s linear infinite;
}

@keyframes fall-66 {
  57.325% {
    transform: translate(61.7224vw, 57.325vh) scale(0.1491);
    top: 57.325vh;
  }

  to {
    transform: translate(64.54235vw, 100vh) scale(0.1491);
    top: 100%;
  }
}

.snow:nth-child(67) {
  opacity: 0.9522;
  transform: translate(85.8883vw, -10px) scale(0.3527);
  animation: fall-67 88s -53s linear infinite;
}

@keyframes fall-67 {
  49.966% {
    transform: translate(82.2307vw, 49.966vh) scale(0.3527);
    top: 49.966vh;
  }

  to {
    transform: translate(84.0595vw, 100vh) scale(0.3527);
    top: 100%;
  }
}

.snow:nth-child(68) {
  opacity: 0.1328;
  transform: translate(10.0673vw, -10px) scale(0.4079);
  animation: fall-68 116s -42s linear infinite;
}

@keyframes fall-68 {
  61.378% {
    transform: translate(14.2426vw, 61.378vh) scale(0.4079);
    top: 61.378vh;
  }

  to {
    transform: translate(12.15495vw, 100vh) scale(0.4079);
    top: 100%;
  }
}

.snow:nth-child(69) {
  opacity: 0.7392;
  transform: translate(50.2331vw, -10px) scale(0.4593);
  animation: fall-69 105s -96s linear infinite;
}

@keyframes fall-69 {
  63.249% {
    transform: translate(41.1804vw, 63.249vh) scale(0.4593);
    top: 63.249vh;
  }

  to {
    transform: translate(45.70675vw, 100vh) scale(0.4593);
    top: 100%;
  }
}

.snow:nth-child(70) {
  opacity: 0.5498;
  transform: translate(6.8507vw, -10px) scale(0.1068);
  animation: fall-70 80s -51s linear infinite;
}

@keyframes fall-70 {
  54.684% {
    transform: translate(-1.7513vw, 54.684vh) scale(0.1068);
    top: 54.684vh;
  }

  to {
    transform: translate(2.5497vw, 100vh) scale(0.1068);
    top: 100%;
  }
}

.snow:nth-child(71) {
  opacity: 0.0662;
  transform: translate(27.9653vw, -10px) scale(0.6515);
  animation: fall-71 103s -87s linear infinite;
}

@keyframes fall-71 {
  52.001% {
    transform: translate(32.7216vw, 52.001vh) scale(0.6515);
    top: 52.001vh;
  }

  to {
    transform: translate(30.34345vw, 100vh) scale(0.6515);
    top: 100%;
  }
}

.snow:nth-child(72) {
  opacity: 0.8085;
  transform: translate(49.0209vw, -10px) scale(0.74);
  animation: fall-72 127s -68s linear infinite;
}

@keyframes fall-72 {
  32.642% {
    transform: translate(58.9537vw, 32.642vh) scale(0.74);
    top: 32.642vh;
  }

  to {
    transform: translate(53.9873vw, 100vh) scale(0.74);
    top: 100%;
  }
}

.snow:nth-child(73) {
  opacity: 0.0983;
  transform: translate(58.3994vw, -10px) scale(0.5313);
  animation: fall-73 111s -40s linear infinite;
}

@keyframes fall-73 {
  56.368% {
    transform: translate(61.5015vw, 56.368vh) scale(0.5313);
    top: 56.368vh;
  }

  to {
    transform: translate(59.95045vw, 100vh) scale(0.5313);
    top: 100%;
  }
}

.snow:nth-child(74) {
  opacity: 0.2516;
  transform: translate(15.1395vw, -10px) scale(0.361);
  animation: fall-74 115s -91s linear infinite;
}

@keyframes fall-74 {
  62.081% {
    transform: translate(13.295vw, 62.081vh) scale(0.361);
    top: 62.081vh;
  }

  to {
    transform: translate(14.21725vw, 100vh) scale(0.361);
    top: 100%;
  }
}

.snow:nth-child(75) {
  opacity: 0.2222;
  transform: translate(89.9792vw, -10px) scale(0.8859);
  animation: fall-75 127s -97s linear infinite;
}

@keyframes fall-75 {
  47.604% {
    transform: translate(85.5931vw, 47.604vh) scale(0.8859);
    top: 47.604vh;
  }

  to {
    transform: translate(87.78615vw, 100vh) scale(0.8859);
    top: 100%;
  }
}

.snow:nth-child(76) {
  opacity: 0.2641;
  transform: translate(79.1283vw, -10px) scale(0.9315);
  animation: fall-76 129s -17s linear infinite;
}

@keyframes fall-76 {
  30.39% {
    transform: translate(84.4113vw, 30.39vh) scale(0.9315);
    top: 30.39vh;
  }

  to {
    transform: translate(81.7698vw, 100vh) scale(0.9315);
    top: 100%;
  }
}

.snow:nth-child(77) {
  opacity: 0.6823;
  transform: translate(69.6469vw, -10px) scale(0.3207);
  animation: fall-77 129s -69s linear infinite;
}

@keyframes fall-77 {
  55.206% {
    transform: translate(64.3856vw, 55.206vh) scale(0.3207);
    top: 55.206vh;
  }

  to {
    transform: translate(67.01625vw, 100vh) scale(0.3207);
    top: 100%;
  }
}

.snow:nth-child(78) {
  opacity: 0.8872;
  transform: translate(9.8216vw, -10px) scale(0.8193);
  animation: fall-78 105s -38s linear infinite;
}

@keyframes fall-78 {
  77.312% {
    transform: translate(12.4761vw, 77.312vh) scale(0.8193);
    top: 77.312vh;
  }

  to {
    transform: translate(11.14885vw, 100vh) scale(0.8193);
    top: 100%;
  }
}

.snow:nth-child(79) {
  opacity: 0.4551;
  transform: translate(68.4864vw, -10px) scale(0.8383);
  animation: fall-79 91s -64s linear infinite;
}

@keyframes fall-79 {
  73.03% {
    transform: translate(59.2913vw, 73.03vh) scale(0.8383);
    top: 73.03vh;
  }

  to {
    transform: translate(63.88885vw, 100vh) scale(0.8383);
    top: 100%;
  }
}

.snow:nth-child(80) {
  opacity: 0.2955;
  transform: translate(88.8468vw, -10px) scale(0.2209);
  animation: fall-80 80s -70s linear infinite;
}

@keyframes fall-80 {
  58.692% {
    transform: translate(79.3268vw, 58.692vh) scale(0.2209);
    top: 58.692vh;
  }

  to {
    transform: translate(84.0868vw, 100vh) scale(0.2209);
    top: 100%;
  }
}

.snow:nth-child(81) {
  opacity: 0.2285;
  transform: translate(66.6399vw, -10px) scale(0.0037);
  animation: fall-81 117s -89s linear infinite;
}

@keyframes fall-81 {
  62.827% {
    transform: translate(73.7982vw, 62.827vh) scale(0.0037);
    top: 62.827vh;
  }

  to {
    transform: translate(70.21905vw, 100vh) scale(0.0037);
    top: 100%;
  }
}

.snow:nth-child(82) {
  opacity: 0.6547;
  transform: translate(62.7103vw, -10px) scale(0.0972);
  animation: fall-82 115s -11s linear infinite;
}

@keyframes fall-82 {
  45.174% {
    transform: translate(67.5625vw, 45.174vh) scale(0.0972);
    top: 45.174vh;
  }

  to {
    transform: translate(65.1364vw, 100vh) scale(0.0972);
    top: 100%;
  }
}

.snow:nth-child(83) {
  opacity: 0.6095;
  transform: translate(82.4083vw, -10px) scale(0.858);
  animation: fall-83 88s -78s linear infinite;
}

@keyframes fall-83 {
  71.635% {
    transform: translate(83.6943vw, 71.635vh) scale(0.858);
    top: 71.635vh;
  }

  to {
    transform: translate(83.0513vw, 100vh) scale(0.858);
    top: 100%;
  }
}

.snow:nth-child(84) {
  opacity: 0.2741;
  transform: translate(51.38vw, -10px) scale(0.2668);
  animation: fall-84 97s -18s linear infinite;
}

@keyframes fall-84 {
  44.419% {
    transform: translate(52.0453vw, 44.419vh) scale(0.2668);
    top: 44.419vh;
  }

  to {
    transform: translate(51.71265vw, 100vh) scale(0.2668);
    top: 100%;
  }
}

.snow:nth-child(85) {
  opacity: 0.994;
  transform: translate(98.3819vw, -10px) scale(0.1156);
  animation: fall-85 87s -55s linear infinite;
}

@keyframes fall-85 {
  56.584% {
    transform: translate(93.2949vw, 56.584vh) scale(0.1156);
    top: 56.584vh;
  }

  to {
    transform: translate(95.8384vw, 100vh) scale(0.1156);
    top: 100%;
  }
}

.snow:nth-child(86) {
  opacity: 0.1502;
  transform: translate(54.9771vw, -10px) scale(0.5042);
  animation: fall-86 104s -18s linear infinite;
}

@keyframes fall-86 {
  64.158% {
    transform: translate(63.5815vw, 64.158vh) scale(0.5042);
    top: 64.158vh;
  }

  to {
    transform: translate(59.2793vw, 100vh) scale(0.5042);
    top: 100%;
  }
}

.snow:nth-child(87) {
  opacity: 0.6502;
  transform: translate(81.3759vw, -10px) scale(0.8954);
  animation: fall-87 125s -16s linear infinite;
}

@keyframes fall-87 {
  75.138% {
    transform: translate(79.0949vw, 75.138vh) scale(0.8954);
    top: 75.138vh;
  }

  to {
    transform: translate(80.2354vw, 100vh) scale(0.8954);
    top: 100%;
  }
}

.snow:nth-child(88) {
  opacity: 0.1547;
  transform: translate(96.9892vw, -10px) scale(0.601);
  animation: fall-88 123s -52s linear infinite;
}

@keyframes fall-88 {
  59.755% {
    transform: translate(93.9011vw, 59.755vh) scale(0.601);
    top: 59.755vh;
  }

  to {
    transform: translate(95.44515vw, 100vh) scale(0.601);
    top: 100%;
  }
}

.snow:nth-child(89) {
  opacity: 0.21;
  transform: translate(45.9217vw, -10px) scale(0.0395);
  animation: fall-89 121s -42s linear infinite;
}

@keyframes fall-89 {
  45.489% {
    transform: translate(42.1344vw, 45.489vh) scale(0.0395);
    top: 45.489vh;
  }

  to {
    transform: translate(44.02805vw, 100vh) scale(0.0395);
    top: 100%;
  }
}

.snow:nth-child(90) {
  opacity: 0.4223;
  transform: translate(21.7592vw, -10px) scale(0.1254);
  animation: fall-90 114s -38s linear infinite;
}

@keyframes fall-90 {
  69.722% {
    transform: translate(27.3058vw, 69.722vh) scale(0.1254);
    top: 69.722vh;
  }

  to {
    transform: translate(24.5325vw, 100vh) scale(0.1254);
    top: 100%;
  }
}

.snow:nth-child(91) {
  opacity: 0.7728;
  transform: translate(43.1133vw, -10px) scale(0.0736);
  animation: fall-91 102s -66s linear infinite;
}

@keyframes fall-91 {
  70.542% {
    transform: translate(52.8677vw, 70.542vh) scale(0.0736);
    top: 70.542vh;
  }

  to {
    transform: translate(47.9905vw, 100vh) scale(0.0736);
    top: 100%;
  }
}

.snow:nth-child(92) {
  opacity: 0.4384;
  transform: translate(65.1565vw, -10px) scale(0.3001);
  animation: fall-92 104s -28s linear infinite;
}

@keyframes fall-92 {
  46.311% {
    transform: translate(69.6514vw, 46.311vh) scale(0.3001);
    top: 46.311vh;
  }

  to {
    transform: translate(67.40395vw, 100vh) scale(0.3001);
    top: 100%;
  }
}

.snow:nth-child(93) {
  opacity: 0.9199;
  transform: translate(98.5169vw, -10px) scale(0.755);
  animation: fall-93 99s -37s linear infinite;
}

@keyframes fall-93 {
  47.056% {
    transform: translate(94.9844vw, 47.056vh) scale(0.755);
    top: 47.056vh;
  }

  to {
    transform: translate(96.75065vw, 100vh) scale(0.755);
    top: 100%;
  }
}

.snow:nth-child(94) {
  opacity: 0.4711;
  transform: translate(87.093vw, -10px) scale(0.4867);
  animation: fall-94 104s -98s linear infinite;
}

@keyframes fall-94 {
  72.054% {
    transform: translate(88.4323vw, 72.054vh) scale(0.4867);
    top: 72.054vh;
  }

  to {
    transform: translate(87.76265vw, 100vh) scale(0.4867);
    top: 100%;
  }
}

.snow:nth-child(95) {
  opacity: 0.5008;
  transform: translate(12.5619vw, -10px) scale(0.2981);
  animation: fall-95 126s -61s linear infinite;
}

@keyframes fall-95 {
  59.493% {
    transform: translate(7.8048vw, 59.493vh) scale(0.2981);
    top: 59.493vh;
  }

  to {
    transform: translate(10.18335vw, 100vh) scale(0.2981);
    top: 100%;
  }
}

.snow:nth-child(96) {
  opacity: 0.4537;
  transform: translate(26.574vw, -10px) scale(0.4858);
  animation: fall-96 115s -18s linear infinite;
}

@keyframes fall-96 {
  47.337% {
    transform: translate(19.5399vw, 47.337vh) scale(0.4858);
    top: 47.337vh;
  }

  to {
    transform: translate(23.05695vw, 100vh) scale(0.4858);
    top: 100%;
  }
}

.snow:nth-child(97) {
  opacity: 0.4394;
  transform: translate(67.2587vw, -10px) scale(0.6965);
  animation: fall-97 103s -93s linear infinite;
}

@keyframes fall-97 {
  79.745% {
    transform: translate(65.7591vw, 79.745vh) scale(0.6965);
    top: 79.745vh;
  }

  to {
    transform: translate(66.5089vw, 100vh) scale(0.6965);
    top: 100%;
  }
}

.snow:nth-child(98) {
  opacity: 0.0575;
  transform: translate(13.6182vw, -10px) scale(0.5525);
  animation: fall-98 130s -66s linear infinite;
}

@keyframes fall-98 {
  45.332% {
    transform: translate(11.6912vw, 45.332vh) scale(0.5525);
    top: 45.332vh;
  }

  to {
    transform: translate(12.6547vw, 100vh) scale(0.5525);
    top: 100%;
  }
}

.snow:nth-child(99) {
  opacity: 0.4162;
  transform: translate(71.8905vw, -10px) scale(0.2284);
  animation: fall-99 106s -97s linear infinite;
}

@keyframes fall-99 {
  30.732% {
    transform: translate(70.9043vw, 30.732vh) scale(0.2284);
    top: 30.732vh;
  }

  to {
    transform: translate(71.3974vw, 100vh) scale(0.2284);
    top: 100%;
  }
}

.snow:nth-child(100) {
  opacity: 0.5249;
  transform: translate(34.8099vw, -10px) scale(0.4086);
  animation: fall-100 98s -77s linear infinite;
}

@keyframes fall-100 {
  71.425% {
    transform: translate(31.3779vw, 71.425vh) scale(0.4086);
    top: 71.425vh;
  }

  to {
    transform: translate(33.0939vw, 100vh) scale(0.4086);
    top: 100%;
  }
}

.snow:nth-child(101) {
  opacity: 0.656;
  transform: translate(94.1708vw, -10px) scale(0.3474);
  animation: fall-101 87s -37s linear infinite;
}

@keyframes fall-101 {
  62.36% {
    transform: translate(100.1635vw, 62.36vh) scale(0.3474);
    top: 62.36vh;
  }

  to {
    transform: translate(97.16715vw, 100vh) scale(0.3474);
    top: 100%;
  }
}

.snow:nth-child(102) {
  opacity: 0.9601;
  transform: translate(8.0882vw, -10px) scale(0.4435);
  animation: fall-102 121s -84s linear infinite;
}

@keyframes fall-102 {
  61.266% {
    transform: translate(0.2493vw, 61.266vh) scale(0.4435);
    top: 61.266vh;
  }

  to {
    transform: translate(4.16875vw, 100vh) scale(0.4435);
    top: 100%;
  }
}

.snow:nth-child(103) {
  opacity: 0.6043;
  transform: translate(6.0763vw, -10px) scale(0.4277);
  animation: fall-103 117s -32s linear infinite;
}

@keyframes fall-103 {
  56.368% {
    transform: translate(8.7872vw, 56.368vh) scale(0.4277);
    top: 56.368vh;
  }

  to {
    transform: translate(7.43175vw, 100vh) scale(0.4277);
    top: 100%;
  }
}

.snow:nth-child(104) {
  opacity: 0.3726;
  transform: translate(16.9657vw, -10px) scale(0.8241);
  animation: fall-104 94s -43s linear infinite;
}

@keyframes fall-104 {
  61.432% {
    transform: translate(26.331vw, 61.432vh) scale(0.8241);
    top: 61.432vh;
  }

  to {
    transform: translate(21.64835vw, 100vh) scale(0.8241);
    top: 100%;
  }
}

.snow:nth-child(105) {
  opacity: 0.6979;
  transform: translate(14.9345vw, -10px) scale(0.4148);
  animation: fall-105 105s -17s linear infinite;
}

@keyframes fall-105 {
  59.524% {
    transform: translate(16.2939vw, 59.524vh) scale(0.4148);
    top: 59.524vh;
  }

  to {
    transform: translate(15.6142vw, 100vh) scale(0.4148);
    top: 100%;
  }
}

.snow:nth-child(106) {
  opacity: 0.9907;
  transform: translate(86.7423vw, -10px) scale(0.1043);
  animation: fall-106 100s -49s linear infinite;
}

@keyframes fall-106 {
  38.209% {
    transform: translate(81.9175vw, 38.209vh) scale(0.1043);
    top: 38.209vh;
  }

  to {
    transform: translate(84.3299vw, 100vh) scale(0.1043);
    top: 100%;
  }
}

.snow:nth-child(107) {
  opacity: 0.7311;
  transform: translate(89.3993vw, -10px) scale(0.6249);
  animation: fall-107 127s -27s linear infinite;
}

@keyframes fall-107 {
  30.163% {
    transform: translate(91.1302vw, 30.163vh) scale(0.6249);
    top: 30.163vh;
  }

  to {
    transform: translate(90.26475vw, 100vh) scale(0.6249);
    top: 100%;
  }
}

.snow:nth-child(108) {
  opacity: 0.3903;
  transform: translate(81.5912vw, -10px) scale(0.4962);
  animation: fall-108 86s -69s linear infinite;
}

@keyframes fall-108 {
  38.722% {
    transform: translate(77.5993vw, 38.722vh) scale(0.4962);
    top: 38.722vh;
  }

  to {
    transform: translate(79.59525vw, 100vh) scale(0.4962);
    top: 100%;
  }
}

.snow:nth-child(109) {
  opacity: 0.5114;
  transform: translate(60.1691vw, -10px) scale(0.2194);
  animation: fall-109 102s -2s linear infinite;
}

@keyframes fall-109 {
  37.92% {
    transform: translate(67.0878vw, 37.92vh) scale(0.2194);
    top: 37.92vh;
  }

  to {
    transform: translate(63.62845vw, 100vh) scale(0.2194);
    top: 100%;
  }
}

.snow:nth-child(110) {
  opacity: 0.4265;
  transform: translate(32.5249vw, -10px) scale(0.1994);
  animation: fall-110 80s -45s linear infinite;
}

@keyframes fall-110 {
  49.237% {
    transform: translate(27.1326vw, 49.237vh) scale(0.1994);
    top: 49.237vh;
  }

  to {
    transform: translate(29.82875vw, 100vh) scale(0.1994);
    top: 100%;
  }
}

.snow:nth-child(111) {
  opacity: 0.3897;
  transform: translate(81.5335vw, -10px) scale(0.8335);
  animation: fall-111 100s -14s linear infinite;
}

@keyframes fall-111 {
  68.729% {
    transform: translate(72.9202vw, 68.729vh) scale(0.8335);
    top: 68.729vh;
  }

  to {
    transform: translate(77.22685vw, 100vh) scale(0.8335);
    top: 100%;
  }
}

.snow:nth-child(112) {
  opacity: 0.0207;
  transform: translate(86.1388vw, -10px) scale(0.6712);
  animation: fall-112 127s -65s linear infinite;
}

@keyframes fall-112 {
  77.759% {
    transform: translate(93.365vw, 77.759vh) scale(0.6712);
    top: 77.759vh;
  }

  to {
    transform: translate(89.7519vw, 100vh) scale(0.6712);
    top: 100%;
  }
}

.snow:nth-child(113) {
  opacity: 0.3089;
  transform: translate(71.6755vw, -10px) scale(0.3905);
  animation: fall-113 117s -68s linear infinite;
}

@keyframes fall-113 {
  33.201% {
    transform: translate(74.0065vw, 33.201vh) scale(0.3905);
    top: 33.201vh;
  }

  to {
    transform: translate(72.841vw, 100vh) scale(0.3905);
    top: 100%;
  }
}

.snow:nth-child(114) {
  opacity: 0.5351;
  transform: translate(63.8686vw, -10px) scale(0.0318);
  animation: fall-114 91s -54s linear infinite;
}

@keyframes fall-114 {
  44.512% {
    transform: translate(62.8371vw, 44.512vh) scale(0.0318);
    top: 44.512vh;
  }

  to {
    transform: translate(63.35285vw, 100vh) scale(0.0318);
    top: 100%;
  }
}

.snow:nth-child(115) {
  opacity: 0.3114;
  transform: translate(7.4334vw, -10px) scale(0.5027);
  animation: fall-115 108s -2s linear infinite;
}

@keyframes fall-115 {
  52.559% {
    transform: translate(9.9192vw, 52.559vh) scale(0.5027);
    top: 52.559vh;
  }

  to {
    transform: translate(8.6763vw, 100vh) scale(0.5027);
    top: 100%;
  }
}

.snow:nth-child(116) {
  opacity: 0.3366;
  transform: translate(70.4117vw, -10px) scale(0.3693);
  animation: fall-116 117s -62s linear infinite;
}

@keyframes fall-116 {
  45.56% {
    transform: translate(66.9329vw, 45.56vh) scale(0.3693);
    top: 45.56vh;
  }

  to {
    transform: translate(68.6723vw, 100vh) scale(0.3693);
    top: 100%;
  }
}

.snow:nth-child(117) {
  opacity: 0.0106;
  transform: translate(42.2996vw, -10px) scale(0.665);
  animation: fall-117 95s -44s linear infinite;
}

@keyframes fall-117 {
  63.786% {
    transform: translate(50.1888vw, 63.786vh) scale(0.665);
    top: 63.786vh;
  }

  to {
    transform: translate(46.2442vw, 100vh) scale(0.665);
    top: 100%;
  }
}

.snow:nth-child(118) {
  opacity: 0.137;
  transform: translate(39.9472vw, -10px) scale(0.1908);
  animation: fall-118 83s -82s linear infinite;
}

@keyframes fall-118 {
  45.086% {
    transform: translate(41.4687vw, 45.086vh) scale(0.1908);
    top: 45.086vh;
  }

  to {
    transform: translate(40.70795vw, 100vh) scale(0.1908);
    top: 100%;
  }
}

.snow:nth-child(119) {
  opacity: 0.551;
  transform: translate(74.14vw, -10px) scale(0.7449);
  animation: fall-119 88s -10s linear infinite;
}

@keyframes fall-119 {
  41.079% {
    transform: translate(75.8261vw, 41.079vh) scale(0.7449);
    top: 41.079vh;
  }

  to {
    transform: translate(74.98305vw, 100vh) scale(0.7449);
    top: 100%;
  }
}

.snow:nth-child(120) {
  opacity: 0.506;
  transform: translate(91.1314vw, -10px) scale(0.6544);
  animation: fall-120 96s -5s linear infinite;
}

@keyframes fall-120 {
  56.152% {
    transform: translate(86.6958vw, 56.152vh) scale(0.6544);
    top: 56.152vh;
  }

  to {
    transform: translate(88.9136vw, 100vh) scale(0.6544);
    top: 100%;
  }
}

.snow:nth-child(121) {
  opacity: 0.0071;
  transform: translate(16.6396vw, -10px) scale(0.8377);
  animation: fall-121 97s -5s linear infinite;
}

@keyframes fall-121 {
  61.934% {
    transform: translate(22.5655vw, 61.934vh) scale(0.8377);
    top: 61.934vh;
  }

  to {
    transform: translate(19.60255vw, 100vh) scale(0.8377);
    top: 100%;
  }
}

.snow:nth-child(122) {
  opacity: 0.7686;
  transform: translate(48.6656vw, -10px) scale(0.2457);
  animation: fall-122 122s -5s linear infinite;
}

@keyframes fall-122 {
  78.553% {
    transform: translate(49.8121vw, 78.553vh) scale(0.2457);
    top: 78.553vh;
  }

  to {
    transform: translate(49.23885vw, 100vh) scale(0.2457);
    top: 100%;
  }
}

.snow:nth-child(123) {
  opacity: 0.1426;
  transform: translate(43.6251vw, -10px) scale(0.3337);
  animation: fall-123 125s -73s linear infinite;
}

@keyframes fall-123 {
  66.217% {
    transform: translate(39.848vw, 66.217vh) scale(0.3337);
    top: 66.217vh;
  }

  to {
    transform: translate(41.73655vw, 100vh) scale(0.3337);
    top: 100%;
  }
}

.snow:nth-child(124) {
  opacity: 0.9183;
  transform: translate(47.8634vw, -10px) scale(0.1349);
  animation: fall-124 114s -39s linear infinite;
}

@keyframes fall-124 {
  78.132% {
    transform: translate(52.7883vw, 78.132vh) scale(0.1349);
    top: 78.132vh;
  }

  to {
    transform: translate(50.32585vw, 100vh) scale(0.1349);
    top: 100%;
  }
}

.snow:nth-child(125) {
  opacity: 0.3997;
  transform: translate(12.2125vw, -10px) scale(0.5523);
  animation: fall-125 128s -53s linear infinite;
}

@keyframes fall-125 {
  50.7% {
    transform: translate(7.7592vw, 50.7vh) scale(0.5523);
    top: 50.7vh;
  }

  to {
    transform: translate(9.98585vw, 100vh) scale(0.5523);
    top: 100%;
  }
}

.snow:nth-child(126) {
  opacity: 0.8899;
  transform: translate(27.7035vw, -10px) scale(0.0774);
  animation: fall-126 84s -85s linear infinite;
}

@keyframes fall-126 {
  74.491% {
    transform: translate(37.0671vw, 74.491vh) scale(0.0774);
    top: 74.491vh;
  }

  to {
    transform: translate(32.3853vw, 100vh) scale(0.0774);
    top: 100%;
  }
}

.snow:nth-child(127) {
  opacity: 0.0353;
  transform: translate(63.1701vw, -10px) scale(0.912);
  animation: fall-127 123s -38s linear infinite;
}

@keyframes fall-127 {
  79.021% {
    transform: translate(65.4486vw, 79.021vh) scale(0.912);
    top: 79.021vh;
  }

  to {
    transform: translate(64.30935vw, 100vh) scale(0.912);
    top: 100%;
  }
}

.snow:nth-child(128) {
  opacity: 0.934;
  transform: translate(33.0796vw, -10px) scale(0.186);
  animation: fall-128 129s -13s linear infinite;
}

@keyframes fall-128 {
  58.232% {
    transform: translate(42.7945vw, 58.232vh) scale(0.186);
    top: 58.232vh;
  }

  to {
    transform: translate(37.93705vw, 100vh) scale(0.186);
    top: 100%;
  }
}

.snow:nth-child(129) {
  opacity: 0.4075;
  transform: translate(97.7383vw, -10px) scale(0.8809);
  animation: fall-129 122s -29s linear infinite;
}

@keyframes fall-129 {
  54.576% {
    transform: translate(106.8704vw, 54.576vh) scale(0.8809);
    top: 54.576vh;
  }

  to {
    transform: translate(102.30435vw, 100vh) scale(0.8809);
    top: 100%;
  }
}

.snow:nth-child(130) {
  opacity: 0.5447;
  transform: translate(98.0944vw, -10px) scale(0.6327);
  animation: fall-130 124s -58s linear infinite;
}

@keyframes fall-130 {
  37.526% {
    transform: translate(100.9585vw, 37.526vh) scale(0.6327);
    top: 37.526vh;
  }

  to {
    transform: translate(99.52645vw, 100vh) scale(0.6327);
    top: 100%;
  }
}

.snow:nth-child(131) {
  opacity: 0.9986;
  transform: translate(7.9911vw, -10px) scale(0.2836);
  animation: fall-131 106s -63s linear infinite;
}

@keyframes fall-131 {
  77.339% {
    transform: translate(1.219vw, 77.339vh) scale(0.2836);
    top: 77.339vh;
  }

  to {
    transform: translate(4.60505vw, 100vh) scale(0.2836);
    top: 100%;
  }
}

.snow:nth-child(132) {
  opacity: 0.6343;
  transform: translate(6.358vw, -10px) scale(0.5488);
  animation: fall-132 107s -72s linear infinite;
}

@keyframes fall-132 {
  67.561% {
    transform: translate(6.0515vw, 67.561vh) scale(0.5488);
    top: 67.561vh;
  }

  to {
    transform: translate(6.20475vw, 100vh) scale(0.5488);
    top: 100%;
  }
}

.snow:nth-child(133) {
  opacity: 0.8743;
  transform: translate(46.5255vw, -10px) scale(0.0438);
  animation: fall-133 115s -65s linear infinite;
}

@keyframes fall-133 {
  65.129% {
    transform: translate(55.6571vw, 65.129vh) scale(0.0438);
    top: 65.129vh;
  }

  to {
    transform: translate(51.0913vw, 100vh) scale(0.0438);
    top: 100%;
  }
}

.snow:nth-child(134) {
  opacity: 0.8795;
  transform: translate(31.6536vw, -10px) scale(0.7907);
  animation: fall-134 84s -64s linear infinite;
}

@keyframes fall-134 {
  61.14% {
    transform: translate(29.6473vw, 61.14vh) scale(0.7907);
    top: 61.14vh;
  }

  to {
    transform: translate(30.65045vw, 100vh) scale(0.7907);
    top: 100%;
  }
}

.snow:nth-child(135) {
  opacity: 0.5606;
  transform: translate(40.0157vw, -10px) scale(0.7307);
  animation: fall-135 102s -31s linear infinite;
}

@keyframes fall-135 {
  69.194% {
    transform: translate(48.0497vw, 69.194vh) scale(0.7307);
    top: 69.194vh;
  }

  to {
    transform: translate(44.0327vw, 100vh) scale(0.7307);
    top: 100%;
  }
}

.snow:nth-child(136) {
  opacity: 0.6293;
  transform: translate(36.2798vw, -10px) scale(0.4042);
  animation: fall-136 125s -71s linear infinite;
}

@keyframes fall-136 {
  33.025% {
    transform: translate(34.6657vw, 33.025vh) scale(0.4042);
    top: 33.025vh;
  }

  to {
    transform: translate(35.47275vw, 100vh) scale(0.4042);
    top: 100%;
  }
}

.snow:nth-child(137) {
  opacity: 0.9976;
  transform: translate(57.5665vw, -10px) scale(0.3168);
  animation: fall-137 112s -61s linear infinite;
}

@keyframes fall-137 {
  79.094% {
    transform: translate(67.1854vw, 79.094vh) scale(0.3168);
    top: 79.094vh;
  }

  to {
    transform: translate(62.37595vw, 100vh) scale(0.3168);
    top: 100%;
  }
}

.snow:nth-child(138) {
  opacity: 0.6192;
  transform: translate(17.453vw, -10px) scale(0.8733);
  animation: fall-138 129s -79s linear infinite;
}

@keyframes fall-138 {
  66.124% {
    transform: translate(7.5915vw, 66.124vh) scale(0.8733);
    top: 66.124vh;
  }

  to {
    transform: translate(12.52225vw, 100vh) scale(0.8733);
    top: 100%;
  }
}

.snow:nth-child(139) {
  opacity: 0.1093;
  transform: translate(58.3212vw, -10px) scale(0.0507);
  animation: fall-139 110s -89s linear infinite;
}

@keyframes fall-139 {
  38.116% {
    transform: translate(52.3919vw, 38.116vh) scale(0.0507);
    top: 38.116vh;
  }

  to {
    transform: translate(55.35655vw, 100vh) scale(0.0507);
    top: 100%;
  }
}

.snow:nth-child(140) {
  opacity: 0.1565;
  transform: translate(52.8044vw, -10px) scale(0.1292);
  animation: fall-140 83s -90s linear infinite;
}

@keyframes fall-140 {
  55.933% {
    transform: translate(52.3834vw, 55.933vh) scale(0.1292);
    top: 55.933vh;
  }

  to {
    transform: translate(52.5939vw, 100vh) scale(0.1292);
    top: 100%;
  }
}

.snow:nth-child(141) {
  opacity: 0.2492;
  transform: translate(86.9023vw, -10px) scale(0.1378);
  animation: fall-141 116s -2s linear infinite;
}

@keyframes fall-141 {
  35.902% {
    transform: translate(80.8821vw, 35.902vh) scale(0.1378);
    top: 35.902vh;
  }

  to {
    transform: translate(83.8922vw, 100vh) scale(0.1378);
    top: 100%;
  }
}

.snow:nth-child(142) {
  opacity: 0.3176;
  transform: translate(33.687vw, -10px) scale(0.4547);
  animation: fall-142 102s -92s linear infinite;
}

@keyframes fall-142 {
  79.958% {
    transform: translate(30.5265vw, 79.958vh) scale(0.4547);
    top: 79.958vh;
  }

  to {
    transform: translate(32.10675vw, 100vh) scale(0.4547);
    top: 100%;
  }
}

.snow:nth-child(143) {
  opacity: 0.9292;
  transform: translate(35.6857vw, -10px) scale(0.5529);
  animation: fall-143 103s -44s linear infinite;
}

@keyframes fall-143 {
  47.42% {
    transform: translate(41.32vw, 47.42vh) scale(0.5529);
    top: 47.42vh;
  }

  to {
    transform: translate(38.50285vw, 100vh) scale(0.5529);
    top: 100%;
  }
}

.snow:nth-child(144) {
  opacity: 0.8473;
  transform: translate(12.5985vw, -10px) scale(0.3481);
  animation: fall-144 110s -23s linear infinite;
}

@keyframes fall-144 {
  55.194% {
    transform: translate(8.7181vw, 55.194vh) scale(0.3481);
    top: 55.194vh;
  }

  to {
    transform: translate(10.6583vw, 100vh) scale(0.3481);
    top: 100%;
  }
}

.snow:nth-child(145) {
  opacity: 0.9364;
  transform: translate(22.6526vw, -10px) scale(0.4976);
  animation: fall-145 130s -81s linear infinite;
}

@keyframes fall-145 {
  43.514% {
    transform: translate(22.8684vw, 43.514vh) scale(0.4976);
    top: 43.514vh;
  }

  to {
    transform: translate(22.7605vw, 100vh) scale(0.4976);
    top: 100%;
  }
}

.snow:nth-child(146) {
  opacity: 0.9096;
  transform: translate(44.467vw, -10px) scale(0.47);
  animation: fall-146 102s -82s linear infinite;
}

@keyframes fall-146 {
  38.023% {
    transform: translate(45.5226vw, 38.023vh) scale(0.47);
    top: 38.023vh;
  }

  to {
    transform: translate(44.9948vw, 100vh) scale(0.47);
    top: 100%;
  }
}

.snow:nth-child(147) {
  opacity: 0.9696;
  transform: translate(0.3519vw, -10px) scale(0.7998);
  animation: fall-147 97s -49s linear infinite;
}

@keyframes fall-147 {
  73.75% {
    transform: translate(3.0235vw, 73.75vh) scale(0.7998);
    top: 73.75vh;
  }

  to {
    transform: translate(1.6877vw, 100vh) scale(0.7998);
    top: 100%;
  }
}

.snow:nth-child(148) {
  opacity: 0.1982;
  transform: translate(52.9607vw, -10px) scale(0.1331);
  animation: fall-148 83s -49s linear infinite;
}

@keyframes fall-148 {
  38.167% {
    transform: translate(49.2773vw, 38.167vh) scale(0.1331);
    top: 38.167vh;
  }

  to {
    transform: translate(51.119vw, 100vh) scale(0.1331);
    top: 100%;
  }
}

.snow:nth-child(149) {
  opacity: 0.1579;
  transform: translate(8.16vw, -10px) scale(0.4992);
  animation: fall-149 96s -21s linear infinite;
}

@keyframes fall-149 {
  61.377% {
    transform: translate(17.6586vw, 61.377vh) scale(0.4992);
    top: 61.377vh;
  }

  to {
    transform: translate(12.9093vw, 100vh) scale(0.4992);
    top: 100%;
  }
}

.snow:nth-child(150) {
  opacity: 0.5696;
  transform: translate(48.9922vw, -10px) scale(0.5198);
  animation: fall-150 125s -33s linear infinite;
}

@keyframes fall-150 {
  58.485% {
    transform: translate(48.6002vw, 58.485vh) scale(0.5198);
    top: 58.485vh;
  }

  to {
    transform: translate(48.7962vw, 100vh) scale(0.5198);
    top: 100%;
  }
}

.snow:nth-child(151) {
  opacity: 0.1161;
  transform: translate(79.1564vw, -10px) scale(0.5625);
  animation: fall-151 107s -36s linear infinite;
}

@keyframes fall-151 {
  42.212% {
    transform: translate(74.8071vw, 42.212vh) scale(0.5625);
    top: 42.212vh;
  }

  to {
    transform: translate(76.98175vw, 100vh) scale(0.5625);
    top: 100%;
  }
}

.snow:nth-child(152) {
  opacity: 0.9336;
  transform: translate(61.8722vw, -10px) scale(0.8399);
  animation: fall-152 88s -46s linear infinite;
}

@keyframes fall-152 {
  77.963% {
    transform: translate(59.1749vw, 77.963vh) scale(0.8399);
    top: 77.963vh;
  }

  to {
    transform: translate(60.52355vw, 100vh) scale(0.8399);
    top: 100%;
  }
}

.snow:nth-child(153) {
  opacity: 0.7507;
  transform: translate(73.4447vw, -10px) scale(0.4988);
  animation: fall-153 103s -2s linear infinite;
}

@keyframes fall-153 {
  31.398% {
    transform: translate(66.6306vw, 31.398vh) scale(0.4988);
    top: 31.398vh;
  }

  to {
    transform: translate(70.03765vw, 100vh) scale(0.4988);
    top: 100%;
  }
}

.snow:nth-child(154) {
  opacity: 0.2082;
  transform: translate(37.0422vw, -10px) scale(0.0155);
  animation: fall-154 122s -63s linear infinite;
}

@keyframes fall-154 {
  72.2% {
    transform: translate(46.5088vw, 72.2vh) scale(0.0155);
    top: 72.2vh;
  }

  to {
    transform: translate(41.7755vw, 100vh) scale(0.0155);
    top: 100%;
  }
}

.snow:nth-child(155) {
  opacity: 0.4061;
  transform: translate(26.2546vw, -10px) scale(0.324);
  animation: fall-155 128s -75s linear infinite;
}

@keyframes fall-155 {
  42.214% {
    transform: translate(24.0496vw, 42.214vh) scale(0.324);
    top: 42.214vh;
  }

  to {
    transform: translate(25.1521vw, 100vh) scale(0.324);
    top: 100%;
  }
}

.snow:nth-child(156) {
  opacity: 0.3021;
  transform: translate(35.1869vw, -10px) scale(0.7452);
  animation: fall-156 116s -61s linear infinite;
}

@keyframes fall-156 {
  47.924% {
    transform: translate(27.5947vw, 47.924vh) scale(0.7452);
    top: 47.924vh;
  }

  to {
    transform: translate(31.3908vw, 100vh) scale(0.7452);
    top: 100%;
  }
}

.snow:nth-child(157) {
  opacity: 0.3766;
  transform: translate(93.1928vw, -10px) scale(0.7729);
  animation: fall-157 80s -62s linear infinite;
}

@keyframes fall-157 {
  55.945% {
    transform: translate(95.2239vw, 55.945vh) scale(0.7729);
    top: 55.945vh;
  }

  to {
    transform: translate(94.20835vw, 100vh) scale(0.7729);
    top: 100%;
  }
}

.snow:nth-child(158) {
  opacity: 0.3683;
  transform: translate(37.3366vw, -10px) scale(0.759);
  animation: fall-158 114s -17s linear infinite;
}

@keyframes fall-158 {
  55.444% {
    transform: translate(38.6079vw, 55.444vh) scale(0.759);
    top: 55.444vh;
  }

  to {
    transform: translate(37.97225vw, 100vh) scale(0.759);
    top: 100%;
  }
}

.snow:nth-child(159) {
  opacity: 0.4906;
  transform: translate(72.8968vw, -10px) scale(0.6687);
  animation: fall-159 109s -23s linear infinite;
}

@keyframes fall-159 {
  46.846% {
    transform: translate(76.3361vw, 46.846vh) scale(0.6687);
    top: 46.846vh;
  }

  to {
    transform: translate(74.61645vw, 100vh) scale(0.6687);
    top: 100%;
  }
}

.snow:nth-child(160) {
  opacity: 0.7595;
  transform: translate(35.7836vw, -10px) scale(0.1529);
  animation: fall-160 90s -10s linear infinite;
}

@keyframes fall-160 {
  59.147% {
    transform: translate(34.7672vw, 59.147vh) scale(0.1529);
    top: 59.147vh;
  }

  to {
    transform: translate(35.2754vw, 100vh) scale(0.1529);
    top: 100%;
  }
}

.snow:nth-child(161) {
  opacity: 0.9966;
  transform: translate(71.4221vw, -10px) scale(0.3271);
  animation: fall-161 102s -79s linear infinite;
}

@keyframes fall-161 {
  42.887% {
    transform: translate(64.8136vw, 42.887vh) scale(0.3271);
    top: 42.887vh;
  }

  to {
    transform: translate(68.11785vw, 100vh) scale(0.3271);
    top: 100%;
  }
}

.snow:nth-child(162) {
  opacity: 0.4387;
  transform: translate(92.1448vw, -10px) scale(0.9471);
  animation: fall-162 126s -61s linear infinite;
}

@keyframes fall-162 {
  76.07% {
    transform: translate(92.3558vw, 76.07vh) scale(0.9471);
    top: 76.07vh;
  }

  to {
    transform: translate(92.2503vw, 100vh) scale(0.9471);
    top: 100%;
  }
}

.snow:nth-child(163) {
  opacity: 0.3612;
  transform: translate(29.7025vw, -10px) scale(0.2842);
  animation: fall-163 91s -100s linear infinite;
}

@keyframes fall-163 {
  63.938% {
    transform: translate(36.3808vw, 63.938vh) scale(0.2842);
    top: 63.938vh;
  }

  to {
    transform: translate(33.04165vw, 100vh) scale(0.2842);
    top: 100%;
  }
}

.snow:nth-child(164) {
  opacity: 0.074;
  transform: translate(61.8213vw, -10px) scale(0.6317);
  animation: fall-164 100s -14s linear infinite;
}

@keyframes fall-164 {
  37.823% {
    transform: translate(58.0258vw, 37.823vh) scale(0.6317);
    top: 37.823vh;
  }

  to {
    transform: translate(59.92355vw, 100vh) scale(0.6317);
    top: 100%;
  }
}

.snow:nth-child(165) {
  opacity: 0.5138;
  transform: translate(20.1052vw, -10px) scale(0.2268);
  animation: fall-165 111s -41s linear infinite;
}

@keyframes fall-165 {
  49.298% {
    transform: translate(28.7362vw, 49.298vh) scale(0.2268);
    top: 49.298vh;
  }

  to {
    transform: translate(24.4207vw, 100vh) scale(0.2268);
    top: 100%;
  }
}

.snow:nth-child(166) {
  opacity: 0.0612;
  transform: translate(73.9277vw, -10px) scale(0.6627);
  animation: fall-166 121s -48s linear infinite;
}

@keyframes fall-166 {
  76.402% {
    transform: translate(73.4961vw, 76.402vh) scale(0.6627);
    top: 76.402vh;
  }

  to {
    transform: translate(73.7119vw, 100vh) scale(0.6627);
    top: 100%;
  }
}

.snow:nth-child(167) {
  opacity: 0.7142;
  transform: translate(27.55vw, -10px) scale(0.9631);
  animation: fall-167 87s -91s linear infinite;
}

@keyframes fall-167 {
  36.999% {
    transform: translate(25.1028vw, 36.999vh) scale(0.9631);
    top: 36.999vh;
  }

  to {
    transform: translate(26.3264vw, 100vh) scale(0.9631);
    top: 100%;
  }
}

.snow:nth-child(168) {
  opacity: 0.0409;
  transform: translate(35.7989vw, -10px) scale(0.7708);
  animation: fall-168 122s -14s linear infinite;
}

@keyframes fall-168 {
  52.267% {
    transform: translate(36.8781vw, 52.267vh) scale(0.7708);
    top: 52.267vh;
  }

  to {
    transform: translate(36.3385vw, 100vh) scale(0.7708);
    top: 100%;
  }
}

.snow:nth-child(169) {
  opacity: 0.2083;
  transform: translate(97.0009vw, -10px) scale(0.3552);
  animation: fall-169 126s -88s linear infinite;
}

@keyframes fall-169 {
  50.569% {
    transform: translate(98.4952vw, 50.569vh) scale(0.3552);
    top: 50.569vh;
  }

  to {
    transform: translate(97.74805vw, 100vh) scale(0.3552);
    top: 100%;
  }
}

.snow:nth-child(170) {
  opacity: 0.9964;
  transform: translate(94.6305vw, -10px) scale(0.6084);
  animation: fall-170 130s -40s linear infinite;
}

@keyframes fall-170 {
  58.178% {
    transform: translate(99.9007vw, 58.178vh) scale(0.6084);
    top: 58.178vh;
  }

  to {
    transform: translate(97.2656vw, 100vh) scale(0.6084);
    top: 100%;
  }
}

.snow:nth-child(171) {
  opacity: 0.1994;
  transform: translate(53.8063vw, -10px) scale(0.5673);
  animation: fall-171 116s -5s linear infinite;
}

@keyframes fall-171 {
  48.848% {
    transform: translate(60.7834vw, 48.848vh) scale(0.5673);
    top: 48.848vh;
  }

  to {
    transform: translate(57.29485vw, 100vh) scale(0.5673);
    top: 100%;
  }
}

.snow:nth-child(172) {
  opacity: 0.2531;
  transform: translate(90.6925vw, -10px) scale(0.835);
  animation: fall-172 113s -16s linear infinite;
}

@keyframes fall-172 {
  59.115% {
    transform: translate(95.5437vw, 59.115vh) scale(0.835);
    top: 59.115vh;
  }

  to {
    transform: translate(93.1181vw, 100vh) scale(0.835);
    top: 100%;
  }
}

.snow:nth-child(173) {
  opacity: 0.1806;
  transform: translate(52.2474vw, -10px) scale(0.2872);
  animation: fall-173 90s -2s linear infinite;
}

@keyframes fall-173 {
  76.783% {
    transform: translate(44.387vw, 76.783vh) scale(0.2872);
    top: 76.783vh;
  }

  to {
    transform: translate(48.3172vw, 100vh) scale(0.2872);
    top: 100%;
  }
}

.snow:nth-child(174) {
  opacity: 0.7042;
  transform: translate(41.7956vw, -10px) scale(0.3226);
  animation: fall-174 121s -96s linear infinite;
}

@keyframes fall-174 {
  30.131% {
    transform: translate(42.9482vw, 30.131vh) scale(0.3226);
    top: 30.131vh;
  }

  to {
    transform: translate(42.3719vw, 100vh) scale(0.3226);
    top: 100%;
  }
}

.snow:nth-child(175) {
  opacity: 0.748;
  transform: translate(28.3302vw, -10px) scale(0.2433);
  animation: fall-175 85s -80s linear infinite;
}

@keyframes fall-175 {
  32.691% {
    transform: translate(29.8553vw, 32.691vh) scale(0.2433);
    top: 32.691vh;
  }

  to {
    transform: translate(29.09275vw, 100vh) scale(0.2433);
    top: 100%;
  }
}

.snow:nth-child(176) {
  opacity: 0.1381;
  transform: translate(20.7274vw, -10px) scale(0.7615);
  animation: fall-176 126s -93s linear infinite;
}

@keyframes fall-176 {
  56.506% {
    transform: translate(24.5943vw, 56.506vh) scale(0.7615);
    top: 56.506vh;
  }

  to {
    transform: translate(22.66085vw, 100vh) scale(0.7615);
    top: 100%;
  }
}

.snow:nth-child(177) {
  opacity: 0.9796;
  transform: translate(30.6367vw, -10px) scale(0.255);
  animation: fall-177 108s -2s linear infinite;
}

@keyframes fall-177 {
  43.682% {
    transform: translate(26.6471vw, 43.682vh) scale(0.255);
    top: 43.682vh;
  }

  to {
    transform: translate(28.6419vw, 100vh) scale(0.255);
    top: 100%;
  }
}

.snow:nth-child(178) {
  opacity: 0.8672;
  transform: translate(26.1013vw, -10px) scale(0.725);
  animation: fall-178 97s -86s linear infinite;
}

@keyframes fall-178 {
  47.741% {
    transform: translate(29.9874vw, 47.741vh) scale(0.725);
    top: 47.741vh;
  }

  to {
    transform: translate(28.04435vw, 100vh) scale(0.725);
    top: 100%;
  }
}

.snow:nth-child(179) {
  opacity: 0.6078;
  transform: translate(90.6897vw, -10px) scale(0.6564);
  animation: fall-179 81s -41s linear infinite;
}

@keyframes fall-179 {
  71.687% {
    transform: translate(92.8457vw, 71.687vh) scale(0.6564);
    top: 71.687vh;
  }

  to {
    transform: translate(91.7677vw, 100vh) scale(0.6564);
    top: 100%;
  }
}

.snow:nth-child(180) {
  opacity: 0.0987;
  transform: translate(1.9531vw, -10px) scale(0.9258);
  animation: fall-180 105s -46s linear infinite;
}

@keyframes fall-180 {
  66.347% {
    transform: translate(-5.8193vw, 66.347vh) scale(0.9258);
    top: 66.347vh;
  }

  to {
    transform: translate(-1.9331vw, 100vh) scale(0.9258);
    top: 100%;
  }
}

.snow:nth-child(181) {
  opacity: 0.5325;
  transform: translate(26.204vw, -10px) scale(0.208);
  animation: fall-181 107s -69s linear infinite;
}

@keyframes fall-181 {
  59.894% {
    transform: translate(18.332vw, 59.894vh) scale(0.208);
    top: 59.894vh;
  }

  to {
    transform: translate(22.268vw, 100vh) scale(0.208);
    top: 100%;
  }
}

.snow:nth-child(182) {
  opacity: 0.6508;
  transform: translate(44.1023vw, -10px) scale(0.9618);
  animation: fall-182 119s -35s linear infinite;
}

@keyframes fall-182 {
  45.786% {
    transform: translate(48.2313vw, 45.786vh) scale(0.9618);
    top: 45.786vh;
  }

  to {
    transform: translate(46.1668vw, 100vh) scale(0.9618);
    top: 100%;
  }
}

.snow:nth-child(183) {
  opacity: 0.0134;
  transform: translate(13.0921vw, -10px) scale(0.4166);
  animation: fall-183 92s -66s linear infinite;
}

@keyframes fall-183 {
  39.91% {
    transform: translate(12.7506vw, 39.91vh) scale(0.4166);
    top: 39.91vh;
  }

  to {
    transform: translate(12.92135vw, 100vh) scale(0.4166);
    top: 100%;
  }
}

.snow:nth-child(184) {
  opacity: 0.3892;
  transform: translate(68.3151vw, -10px) scale(0.335);
  animation: fall-184 122s -55s linear infinite;
}

@keyframes fall-184 {
  50.612% {
    transform: translate(61.6544vw, 50.612vh) scale(0.335);
    top: 50.612vh;
  }

  to {
    transform: translate(64.98475vw, 100vh) scale(0.335);
    top: 100%;
  }
}

.snow:nth-child(185) {
  opacity: 0.0789;
  transform: translate(50.4002vw, -10px) scale(0.7869);
  animation: fall-185 92s -15s linear infinite;
}

@keyframes fall-185 {
  52.485% {
    transform: translate(40.7096vw, 52.485vh) scale(0.7869);
    top: 52.485vh;
  }

  to {
    transform: translate(45.5549vw, 100vh) scale(0.7869);
    top: 100%;
  }
}

.snow:nth-child(186) {
  opacity: 0.8819;
  transform: translate(75.7468vw, -10px) scale(0.5513);
  animation: fall-186 97s -69s linear infinite;
}

@keyframes fall-186 {
  66.171% {
    transform: translate(67.3998vw, 66.171vh) scale(0.5513);
    top: 66.171vh;
  }

  to {
    transform: translate(71.5733vw, 100vh) scale(0.5513);
    top: 100%;
  }
}

.snow:nth-child(187) {
  opacity: 0.6587;
  transform: translate(8.3218vw, -10px) scale(0.0121);
  animation: fall-187 108s -1s linear infinite;
}

@keyframes fall-187 {
  59.097% {
    transform: translate(15.5048vw, 59.097vh) scale(0.0121);
    top: 59.097vh;
  }

  to {
    transform: translate(11.9133vw, 100vh) scale(0.0121);
    top: 100%;
  }
}

.snow:nth-child(188) {
  opacity: 0.1436;
  transform: translate(10.1269vw, -10px) scale(0.7197);
  animation: fall-188 86s -10s linear infinite;
}

@keyframes fall-188 {
  53.453% {
    transform: translate(5.4891vw, 53.453vh) scale(0.7197);
    top: 53.453vh;
  }

  to {
    transform: translate(7.808vw, 100vh) scale(0.7197);
    top: 100%;
  }
}

.snow:nth-child(189) {
  opacity: 0.3069;
  transform: translate(36.2163vw, -10px) scale(0.0372);
  animation: fall-189 96s -62s linear infinite;
}

@keyframes fall-189 {
  75.246% {
    transform: translate(36.3127vw, 75.246vh) scale(0.0372);
    top: 75.246vh;
  }

  to {
    transform: translate(36.2645vw, 100vh) scale(0.0372);
    top: 100%;
  }
}

.snow:nth-child(190) {
  opacity: 0.4379;
  transform: translate(8.2707vw, -10px) scale(0.7509);
  animation: fall-190 111s -83s linear infinite;
}

@keyframes fall-190 {
  47.282% {
    transform: translate(6.0238vw, 47.282vh) scale(0.7509);
    top: 47.282vh;
  }

  to {
    transform: translate(7.14725vw, 100vh) scale(0.7509);
    top: 100%;
  }
}

.snow:nth-child(191) {
  opacity: 0.1193;
  transform: translate(74.637vw, -10px) scale(0.5264);
  animation: fall-191 114s -32s linear infinite;
}

@keyframes fall-191 {
  76.915% {
    transform: translate(83.2824vw, 76.915vh) scale(0.5264);
    top: 76.915vh;
  }

  to {
    transform: translate(78.9597vw, 100vh) scale(0.5264);
    top: 100%;
  }
}

.snow:nth-child(192) {
  opacity: 0.1249;
  transform: translate(17.5074vw, -10px) scale(0.278);
  animation: fall-192 124s -53s linear infinite;
}

@keyframes fall-192 {
  46.02% {
    transform: translate(17.107vw, 46.02vh) scale(0.278);
    top: 46.02vh;
  }

  to {
    transform: translate(17.3072vw, 100vh) scale(0.278);
    top: 100%;
  }
}

.snow:nth-child(193) {
  opacity: 0.5597;
  transform: translate(23.5489vw, -10px) scale(0.7737);
  animation: fall-193 117s -86s linear infinite;
}

@keyframes fall-193 {
  73.302% {
    transform: translate(15.1418vw, 73.302vh) scale(0.7737);
    top: 73.302vh;
  }

  to {
    transform: translate(19.34535vw, 100vh) scale(0.7737);
    top: 100%;
  }
}

.snow:nth-child(194) {
  opacity: 0.7209;
  transform: translate(88.6091vw, -10px) scale(0.3774);
  animation: fall-194 111s -14s linear infinite;
}

@keyframes fall-194 {
  37.15% {
    transform: translate(85.3662vw, 37.15vh) scale(0.3774);
    top: 37.15vh;
  }

  to {
    transform: translate(86.98765vw, 100vh) scale(0.3774);
    top: 100%;
  }
}

.snow:nth-child(195) {
  opacity: 0.8185;
  transform: translate(49.0472vw, -10px) scale(0.887);
  animation: fall-195 95s -95s linear infinite;
}

@keyframes fall-195 {
  60.842% {
    transform: translate(43.5715vw, 60.842vh) scale(0.887);
    top: 60.842vh;
  }

  to {
    transform: translate(46.30935vw, 100vh) scale(0.887);
    top: 100%;
  }
}

.snow:nth-child(196) {
  opacity: 0.8496;
  transform: translate(22.7104vw, -10px) scale(0.0048);
  animation: fall-196 110s -78s linear infinite;
}

@keyframes fall-196 {
  66.574% {
    transform: translate(20.3523vw, 66.574vh) scale(0.0048);
    top: 66.574vh;
  }

  to {
    transform: translate(21.53135vw, 100vh) scale(0.0048);
    top: 100%;
  }
}

.snow:nth-child(197) {
  opacity: 0.5019;
  transform: translate(51.7819vw, -10px) scale(0.3247);
  animation: fall-197 90s -95s linear infinite;
}

@keyframes fall-197 {
  37.183% {
    transform: translate(59.3823vw, 37.183vh) scale(0.3247);
    top: 37.183vh;
  }

  to {
    transform: translate(55.5821vw, 100vh) scale(0.3247);
    top: 100%;
  }
}

.snow:nth-child(198) {
  opacity: 0.7863;
  transform: translate(46.0677vw, -10px) scale(0.8003);
  animation: fall-198 99s -54s linear infinite;
}

@keyframes fall-198 {
  76.839% {
    transform: translate(38.3155vw, 76.839vh) scale(0.8003);
    top: 76.839vh;
  }

  to {
    transform: translate(42.1916vw, 100vh) scale(0.8003);
    top: 100%;
  }
}

.snow:nth-child(199) {
  opacity: 0.1479;
  transform: translate(50.9228vw, -10px) scale(0.3977);
  animation: fall-199 99s -82s linear infinite;
}

@keyframes fall-199 {
  76.908% {
    transform: translate(56.805vw, 76.908vh) scale(0.3977);
    top: 76.908vh;
  }

  to {
    transform: translate(53.8639vw, 100vh) scale(0.3977);
    top: 100%;
  }
}

.snow:nth-child(200) {
  opacity: 0.7744;
  transform: translate(2.6825vw, -10px) scale(0.8485);
  animation: fall-200 99s -16s linear infinite;
}

@keyframes fall-200 {
  34.178% {
    transform: translate(-4.686vw, 34.178vh) scale(0.8485);
    top: 34.178vh;
  }

  to {
    transform: translate(-1.00175vw, 100vh) scale(0.8485);
    top: 100%;
  }
}

.snow:nth-child(201) {
  opacity: 0.7658;
  transform: translate(13.8466vw, -10px) scale(0.1187);
  animation: fall-201 117s -19s linear infinite;
}

@keyframes fall-201 {
  36.473% {
    transform: translate(23.7629vw, 36.473vh) scale(0.1187);
    top: 36.473vh;
  }

  to {
    transform: translate(18.80475vw, 100vh) scale(0.1187);
    top: 100%;
  }
}

.snow:nth-child(202) {
  opacity: 0.6094;
  transform: translate(16.1977vw, -10px) scale(0.9086);
  animation: fall-202 121s -56s linear infinite;
}

@keyframes fall-202 {
  31.802% {
    transform: translate(24.1388vw, 31.802vh) scale(0.9086);
    top: 31.802vh;
  }

  to {
    transform: translate(20.16825vw, 100vh) scale(0.9086);
    top: 100%;
  }
}

.snow:nth-child(203) {
  opacity: 0.282;
  transform: translate(43.3665vw, -10px) scale(0.8668);
  animation: fall-203 110s -41s linear infinite;
}

@keyframes fall-203 {
  39.47% {
    transform: translate(38.8533vw, 39.47vh) scale(0.8668);
    top: 39.47vh;
  }

  to {
    transform: translate(41.1099vw, 100vh) scale(0.8668);
    top: 100%;
  }
}

.snow:nth-child(204) {
  opacity: 0.2812;
  transform: translate(31.7138vw, -10px) scale(0.2492);
  animation: fall-204 84s -57s linear infinite;
}

@keyframes fall-204 {
  49.498% {
    transform: translate(35.8602vw, 49.498vh) scale(0.2492);
    top: 49.498vh;
  }

  to {
    transform: translate(33.787vw, 100vh) scale(0.2492);
    top: 100%;
  }
}

.snow:nth-child(205) {
  opacity: 0.9911;
  transform: translate(38.8176vw, -10px) scale(0.5079);
  animation: fall-205 122s -45s linear infinite;
}

@keyframes fall-205 {
  51.138% {
    transform: translate(33.0865vw, 51.138vh) scale(0.5079);
    top: 51.138vh;
  }

  to {
    transform: translate(35.95205vw, 100vh) scale(0.5079);
    top: 100%;
  }
}

.snow:nth-child(206) {
  opacity: 0.6906;
  transform: translate(34.5198vw, -10px) scale(0.7721);
  animation: fall-206 122s -31s linear infinite;
}

@keyframes fall-206 {
  41.142% {
    transform: translate(36.9532vw, 41.142vh) scale(0.7721);
    top: 41.142vh;
  }

  to {
    transform: translate(35.7365vw, 100vh) scale(0.7721);
    top: 100%;
  }
}

.snow:nth-child(207) {
  opacity: 0.1736;
  transform: translate(44.6162vw, -10px) scale(0.5074);
  animation: fall-207 89s -44s linear infinite;
}

@keyframes fall-207 {
  64.331% {
    transform: translate(50.5748vw, 64.331vh) scale(0.5074);
    top: 64.331vh;
  }

  to {
    transform: translate(47.5955vw, 100vh) scale(0.5074);
    top: 100%;
  }
}

.snow:nth-child(208) {
  opacity: 0.8182;
  transform: translate(33.9495vw, -10px) scale(0.6017);
  animation: fall-208 89s -78s linear infinite;
}

@keyframes fall-208 {
  47.145% {
    transform: translate(41.962vw, 47.145vh) scale(0.6017);
    top: 47.145vh;
  }

  to {
    transform: translate(37.95575vw, 100vh) scale(0.6017);
    top: 100%;
  }
}

.snow:nth-child(209) {
  opacity: 0.2087;
  transform: translate(37.2286vw, -10px) scale(0.7788);
  animation: fall-209 103s -23s linear infinite;
}

@keyframes fall-209 {
  72.714% {
    transform: translate(36.5133vw, 72.714vh) scale(0.7788);
    top: 72.714vh;
  }

  to {
    transform: translate(36.87095vw, 100vh) scale(0.7788);
    top: 100%;
  }
}

.snow:nth-child(210) {
  opacity: 0.321;
  transform: translate(69.9175vw, -10px) scale(0.6981);
  animation: fall-210 108s -70s linear infinite;
}

@keyframes fall-210 {
  56.047% {
    transform: translate(79.1095vw, 56.047vh) scale(0.6981);
    top: 56.047vh;
  }

  to {
    transform: translate(74.5135vw, 100vh) scale(0.6981);
    top: 100%;
  }
}

.snow:nth-child(211) {
  opacity: 0.4208;
  transform: translate(67.6722vw, -10px) scale(0.0943);
  animation: fall-211 123s -95s linear infinite;
}

@keyframes fall-211 {
  53.868% {
    transform: translate(67.4339vw, 53.868vh) scale(0.0943);
    top: 53.868vh;
  }

  to {
    transform: translate(67.55305vw, 100vh) scale(0.0943);
    top: 100%;
  }
}

.snow:nth-child(212) {
  opacity: 0.8168;
  transform: translate(47.5966vw, -10px) scale(0.0439);
  animation: fall-212 124s -52s linear infinite;
}

@keyframes fall-212 {
  72.136% {
    transform: translate(48.7329vw, 72.136vh) scale(0.0439);
    top: 72.136vh;
  }

  to {
    transform: translate(48.16475vw, 100vh) scale(0.0439);
    top: 100%;
  }
}

.snow:nth-child(213) {
  opacity: 0.2058;
  transform: translate(7.0564vw, -10px) scale(0.9162);
  animation: fall-213 101s -62s linear infinite;
}

@keyframes fall-213 {
  37.031% {
    transform: translate(2.2189vw, 37.031vh) scale(0.9162);
    top: 37.031vh;
  }

  to {
    transform: translate(4.63765vw, 100vh) scale(0.9162);
    top: 100%;
  }
}

.snow:nth-child(214) {
  opacity: 0.8741;
  transform: translate(19.8072vw, -10px) scale(0.612);
  animation: fall-214 96s -8s linear infinite;
}

@keyframes fall-214 {
  36.901% {
    transform: translate(25.8778vw, 36.901vh) scale(0.612);
    top: 36.901vh;
  }

  to {
    transform: translate(22.8425vw, 100vh) scale(0.612);
    top: 100%;
  }
}

.snow:nth-child(215) {
  opacity: 0.8204;
  transform: translate(1.6654vw, -10px) scale(0.9159);
  animation: fall-215 123s -3s linear infinite;
}

@keyframes fall-215 {
  44.001% {
    transform: translate(7.6833vw, 44.001vh) scale(0.9159);
    top: 44.001vh;
  }

  to {
    transform: translate(4.67435vw, 100vh) scale(0.9159);
    top: 100%;
  }
}

.snow:nth-child(216) {
  opacity: 0.6891;
  transform: translate(94.2688vw, -10px) scale(0.7292);
  animation: fall-216 125s -26s linear infinite;
}

@keyframes fall-216 {
  66.089% {
    transform: translate(102.174vw, 66.089vh) scale(0.7292);
    top: 66.089vh;
  }

  to {
    transform: translate(98.2214vw, 100vh) scale(0.7292);
    top: 100%;
  }
}

.snow:nth-child(217) {
  opacity: 0.1084;
  transform: translate(97.4446vw, -10px) scale(0.7576);
  animation: fall-217 101s -81s linear infinite;
}

@keyframes fall-217 {
  41.952% {
    transform: translate(102.8269vw, 41.952vh) scale(0.7576);
    top: 41.952vh;
  }

  to {
    transform: translate(100.13575vw, 100vh) scale(0.7576);
    top: 100%;
  }
}

.snow:nth-child(218) {
  opacity: 0.1956;
  transform: translate(99.7716vw, -10px) scale(0.294);
  animation: fall-218 86s -69s linear infinite;
}

@keyframes fall-218 {
  77.251% {
    transform: translate(107.603vw, 77.251vh) scale(0.294);
    top: 77.251vh;
  }

  to {
    transform: translate(103.6873vw, 100vh) scale(0.294);
    top: 100%;
  }
}

.snow:nth-child(219) {
  opacity: 0.7495;
  transform: translate(99.2212vw, -10px) scale(0.1239);
  animation: fall-219 85s -38s linear infinite;
}

@keyframes fall-219 {
  37.937% {
    transform: translate(105.9063vw, 37.937vh) scale(0.1239);
    top: 37.937vh;
  }

  to {
    transform: translate(102.56375vw, 100vh) scale(0.1239);
    top: 100%;
  }
}

.snow:nth-child(220) {
  opacity: 0.2505;
  transform: translate(62.1168vw, -10px) scale(0.4223);
  animation: fall-220 102s -30s linear infinite;
}

@keyframes fall-220 {
  37.04% {
    transform: translate(62.1224vw, 37.04vh) scale(0.4223);
    top: 37.04vh;
  }

  to {
    transform: translate(62.1196vw, 100vh) scale(0.4223);
    top: 100%;
  }
}

.snow:nth-child(221) {
  opacity: 0.2555;
  transform: translate(49.0243vw, -10px) scale(0.1909);
  animation: fall-221 114s -46s linear infinite;
}

@keyframes fall-221 {
  70.199% {
    transform: translate(48.7781vw, 70.199vh) scale(0.1909);
    top: 70.199vh;
  }

  to {
    transform: translate(48.9012vw, 100vh) scale(0.1909);
    top: 100%;
  }
}

.snow:nth-child(222) {
  opacity: 0.1869;
  transform: translate(34.6178vw, -10px) scale(0.5575);
  animation: fall-222 104s -24s linear infinite;
}

@keyframes fall-222 {
  41.872% {
    transform: translate(35.2587vw, 41.872vh) scale(0.5575);
    top: 41.872vh;
  }

  to {
    transform: translate(34.93825vw, 100vh) scale(0.5575);
    top: 100%;
  }
}

.snow:nth-child(223) {
  opacity: 0.0556;
  transform: translate(97.4436vw, -10px) scale(0.0377);
  animation: fall-223 102s -26s linear infinite;
}

@keyframes fall-223 {
  41.532% {
    transform: translate(101.2538vw, 41.532vh) scale(0.0377);
    top: 41.532vh;
  }

  to {
    transform: translate(99.3487vw, 100vh) scale(0.0377);
    top: 100%;
  }
}

.snow:nth-child(224) {
  opacity: 0.1248;
  transform: translate(40.6267vw, -10px) scale(0.4214);
  animation: fall-224 106s -60s linear infinite;
}

@keyframes fall-224 {
  43.147% {
    transform: translate(37.0053vw, 43.147vh) scale(0.4214);
    top: 43.147vh;
  }

  to {
    transform: translate(38.816vw, 100vh) scale(0.4214);
    top: 100%;
  }
}

.snow:nth-child(225) {
  opacity: 0.6838;
  transform: translate(60.6503vw, -10px) scale(0.9724);
  animation: fall-225 128s -76s linear infinite;
}

@keyframes fall-225 {
  43.557% {
    transform: translate(54.9477vw, 43.557vh) scale(0.9724);
    top: 43.557vh;
  }

  to {
    transform: translate(57.799vw, 100vh) scale(0.9724);
    top: 100%;
  }
}

.snow:nth-child(226) {
  opacity: 0.0257;
  transform: translate(65.5466vw, -10px) scale(0.3993);
  animation: fall-226 99s -93s linear infinite;
}

@keyframes fall-226 {
  64.183% {
    transform: translate(60.174vw, 64.183vh) scale(0.3993);
    top: 64.183vh;
  }

  to {
    transform: translate(62.8603vw, 100vh) scale(0.3993);
    top: 100%;
  }
}

.snow:nth-child(227) {
  opacity: 0.7076;
  transform: translate(4.1198vw, -10px) scale(0.0919);
  animation: fall-227 109s -78s linear infinite;
}

@keyframes fall-227 {
  33.513% {
    transform: translate(8.464vw, 33.513vh) scale(0.0919);
    top: 33.513vh;
  }

  to {
    transform: translate(6.2919vw, 100vh) scale(0.0919);
    top: 100%;
  }
}

.snow:nth-child(228) {
  opacity: 0.4854;
  transform: translate(48.114vw, -10px) scale(0.9716);
  animation: fall-228 88s -38s linear infinite;
}

@keyframes fall-228 {
  39.882% {
    transform: translate(39.1091vw, 39.882vh) scale(0.9716);
    top: 39.882vh;
  }

  to {
    transform: translate(43.61155vw, 100vh) scale(0.9716);
    top: 100%;
  }
}

.snow:nth-child(229) {
  opacity: 0.0032;
  transform: translate(34.1152vw, -10px) scale(0.2718);
  animation: fall-229 89s -50s linear infinite;
}

@keyframes fall-229 {
  58.162% {
    transform: translate(41.5948vw, 58.162vh) scale(0.2718);
    top: 58.162vh;
  }

  to {
    transform: translate(37.855vw, 100vh) scale(0.2718);
    top: 100%;
  }
}

.snow:nth-child(230) {
  opacity: 0.9983;
  transform: translate(23.4702vw, -10px) scale(0.6621);
  animation: fall-230 126s -89s linear infinite;
}

@keyframes fall-230 {
  30.814% {
    transform: translate(18.1103vw, 30.814vh) scale(0.6621);
    top: 30.814vh;
  }

  to {
    transform: translate(20.79025vw, 100vh) scale(0.6621);
    top: 100%;
  }
}

.snow:nth-child(231) {
  opacity: 0.5996;
  transform: translate(2.4135vw, -10px) scale(0.0427);
  animation: fall-231 88s -86s linear infinite;
}

@keyframes fall-231 {
  35.773% {
    transform: translate(-4.5267vw, 35.773vh) scale(0.0427);
    top: 35.773vh;
  }

  to {
    transform: translate(-1.0566vw, 100vh) scale(0.0427);
    top: 100%;
  }
}

.snow:nth-child(232) {
  opacity: 0.9241;
  transform: translate(85.2682vw, -10px) scale(0.2238);
  animation: fall-232 103s -4s linear infinite;
}

@keyframes fall-232 {
  31.622% {
    transform: translate(76.8804vw, 31.622vh) scale(0.2238);
    top: 31.622vh;
  }

  to {
    transform: translate(81.0743vw, 100vh) scale(0.2238);
    top: 100%;
  }
}

.snow:nth-child(233) {
  opacity: 0.5718;
  transform: translate(46.5035vw, -10px) scale(0.6623);
  animation: fall-233 122s -37s linear infinite;
}

@keyframes fall-233 {
  66.86% {
    transform: translate(43.4351vw, 66.86vh) scale(0.6623);
    top: 66.86vh;
  }

  to {
    transform: translate(44.9693vw, 100vh) scale(0.6623);
    top: 100%;
  }
}

.snow:nth-child(234) {
  opacity: 0.8223;
  transform: translate(47.7677vw, -10px) scale(0.9535);
  animation: fall-234 93s -15s linear infinite;
}

@keyframes fall-234 {
  74.442% {
    transform: translate(39.3648vw, 74.442vh) scale(0.9535);
    top: 74.442vh;
  }

  to {
    transform: translate(43.56625vw, 100vh) scale(0.9535);
    top: 100%;
  }
}

.snow:nth-child(235) {
  opacity: 0.0484;
  transform: translate(96.4122vw, -10px) scale(0.5293);
  animation: fall-235 91s -43s linear infinite;
}

@keyframes fall-235 {
  56.547% {
    transform: translate(86.4495vw, 56.547vh) scale(0.5293);
    top: 56.547vh;
  }

  to {
    transform: translate(91.43085vw, 100vh) scale(0.5293);
    top: 100%;
  }
}

.snow:nth-child(236) {
  opacity: 0.391;
  transform: translate(50.9587vw, -10px) scale(0.7347);
  animation: fall-236 102s -40s linear infinite;
}

@keyframes fall-236 {
  57.486% {
    transform: translate(42.315vw, 57.486vh) scale(0.7347);
    top: 57.486vh;
  }

  to {
    transform: translate(46.63685vw, 100vh) scale(0.7347);
    top: 100%;
  }
}

.snow:nth-child(237) {
  opacity: 0.5993;
  transform: translate(30.5658vw, -10px) scale(0.3853);
  animation: fall-237 87s -86s linear infinite;
}

@keyframes fall-237 {
  68.045% {
    transform: translate(37.8055vw, 68.045vh) scale(0.3853);
    top: 68.045vh;
  }

  to {
    transform: translate(34.18565vw, 100vh) scale(0.3853);
    top: 100%;
  }
}

.snow:nth-child(238) {
  opacity: 0.1483;
  transform: translate(44.3489vw, -10px) scale(0.2647);
  animation: fall-238 102s -8s linear infinite;
}

@keyframes fall-238 {
  59.17% {
    transform: translate(45.8894vw, 59.17vh) scale(0.2647);
    top: 59.17vh;
  }

  to {
    transform: translate(45.11915vw, 100vh) scale(0.2647);
    top: 100%;
  }
}

.snow:nth-child(239) {
  opacity: 0.6594;
  transform: translate(77.682vw, -10px) scale(0.4523);
  animation: fall-239 121s -31s linear infinite;
}

@keyframes fall-239 {
  65.154% {
    transform: translate(69.6984vw, 65.154vh) scale(0.4523);
    top: 65.154vh;
  }

  to {
    transform: translate(73.6902vw, 100vh) scale(0.4523);
    top: 100%;
  }
}

.snow:nth-child(240) {
  opacity: 0.524;
  transform: translate(43.1822vw, -10px) scale(0.2052);
  animation: fall-240 128s -55s linear infinite;
}

@keyframes fall-240 {
  73.88% {
    transform: translate(51.0616vw, 73.88vh) scale(0.2052);
    top: 73.88vh;
  }

  to {
    transform: translate(47.1219vw, 100vh) scale(0.2052);
    top: 100%;
  }
}

.snow:nth-child(241) {
  opacity: 0.3197;
  transform: translate(9.0007vw, -10px) scale(0.9343);
  animation: fall-241 124s -46s linear infinite;
}

@keyframes fall-241 {
  37.493% {
    transform: translate(1.5196vw, 37.493vh) scale(0.9343);
    top: 37.493vh;
  }

  to {
    transform: translate(5.26015vw, 100vh) scale(0.9343);
    top: 100%;
  }
}

.snow:nth-child(242) {
  opacity: 0.4445;
  transform: translate(7.8918vw, -10px) scale(0.4377);
  animation: fall-242 87s -9s linear infinite;
}

@keyframes fall-242 {
  47.476% {
    transform: translate(8.4926vw, 47.476vh) scale(0.4377);
    top: 47.476vh;
  }

  to {
    transform: translate(8.1922vw, 100vh) scale(0.4377);
    top: 100%;
  }
}

.snow:nth-child(243) {
  opacity: 0.8497;
  transform: translate(53.7766vw, -10px) scale(0.0374);
  animation: fall-243 93s -81s linear infinite;
}

@keyframes fall-243 {
  52.214% {
    transform: translate(47.1057vw, 52.214vh) scale(0.0374);
    top: 52.214vh;
  }

  to {
    transform: translate(50.44115vw, 100vh) scale(0.0374);
    top: 100%;
  }
}

.snow:nth-child(244) {
  opacity: 0.6158;
  transform: translate(78.8594vw, -10px) scale(0.6474);
  animation: fall-244 109s -45s linear infinite;
}

@keyframes fall-244 {
  61.757% {
    transform: translate(85.6574vw, 61.757vh) scale(0.6474);
    top: 61.757vh;
  }

  to {
    transform: translate(82.2584vw, 100vh) scale(0.6474);
    top: 100%;
  }
}

.snow:nth-child(245) {
  opacity: 0.1142;
  transform: translate(76.3605vw, -10px) scale(0.5782);
  animation: fall-245 102s -85s linear infinite;
}

@keyframes fall-245 {
  71.097% {
    transform: translate(67.6101vw, 71.097vh) scale(0.5782);
    top: 71.097vh;
  }

  to {
    transform: translate(71.9853vw, 100vh) scale(0.5782);
    top: 100%;
  }
}

.snow:nth-child(246) {
  opacity: 0.8486;
  transform: translate(71.2383vw, -10px) scale(0.1674);
  animation: fall-246 99s -53s linear infinite;
}

@keyframes fall-246 {
  62.151% {
    transform: translate(64.6888vw, 62.151vh) scale(0.1674);
    top: 62.151vh;
  }

  to {
    transform: translate(67.96355vw, 100vh) scale(0.1674);
    top: 100%;
  }
}

.snow:nth-child(247) {
  opacity: 0.6318;
  transform: translate(96.5694vw, -10px) scale(0.921);
  animation: fall-247 126s -80s linear infinite;
}

@keyframes fall-247 {
  61.551% {
    transform: translate(91.9192vw, 61.551vh) scale(0.921);
    top: 61.551vh;
  }

  to {
    transform: translate(94.2443vw, 100vh) scale(0.921);
    top: 100%;
  }
}

.snow:nth-child(248) {
  opacity: 0.3667;
  transform: translate(96.0706vw, -10px) scale(0.5735);
  animation: fall-248 109s -10s linear infinite;
}

@keyframes fall-248 {
  45.627% {
    transform: translate(94.6475vw, 45.627vh) scale(0.5735);
    top: 45.627vh;
  }

  to {
    transform: translate(95.35905vw, 100vh) scale(0.5735);
    top: 100%;
  }
}

.snow:nth-child(249) {
  opacity: 0.2765;
  transform: translate(20.3865vw, -10px) scale(0.7021);
  animation: fall-249 80s -53s linear infinite;
}

@keyframes fall-249 {
  49.793% {
    transform: translate(13.303vw, 49.793vh) scale(0.7021);
    top: 49.793vh;
  }

  to {
    transform: translate(16.84475vw, 100vh) scale(0.7021);
    top: 100%;
  }
}

.snow:nth-child(250) {
  opacity: 0.3473;
  transform: translate(90.2439vw, -10px) scale(0.782);
  animation: fall-250 86s -88s linear infinite;
}

@keyframes fall-250 {
  31.846% {
    transform: translate(99.8817vw, 31.846vh) scale(0.782);
    top: 31.846vh;
  }

  to {
    transform: translate(95.0628vw, 100vh) scale(0.782);
    top: 100%;
  }
}

.snow:nth-child(251) {
  opacity: 0.8555;
  transform: translate(41.2817vw, -10px) scale(0.2714);
  animation: fall-251 97s -45s linear infinite;
}

@keyframes fall-251 {
  70.865% {
    transform: translate(44.2179vw, 70.865vh) scale(0.2714);
    top: 70.865vh;
  }

  to {
    transform: translate(42.7498vw, 100vh) scale(0.2714);
    top: 100%;
  }
}

.snow:nth-child(252) {
  opacity: 0.1423;
  transform: translate(57.6535vw, -10px) scale(0.0413);
  animation: fall-252 85s -12s linear infinite;
}

@keyframes fall-252 {
  53.552% {
    transform: translate(61.8495vw, 53.552vh) scale(0.0413);
    top: 53.552vh;
  }

  to {
    transform: translate(59.7515vw, 100vh) scale(0.0413);
    top: 100%;
  }
}

.snow:nth-child(253) {
  opacity: 0.2177;
  transform: translate(33.5848vw, -10px) scale(0.1605);
  animation: fall-253 123s -28s linear infinite;
}

@keyframes fall-253 {
  76.948% {
    transform: translate(43.1022vw, 76.948vh) scale(0.1605);
    top: 76.948vh;
  }

  to {
    transform: translate(38.3435vw, 100vh) scale(0.1605);
    top: 100%;
  }
}

.snow:nth-child(254) {
  opacity: 0.3322;
  transform: translate(2.0901vw, -10px) scale(0.5044);
  animation: fall-254 99s -7s linear infinite;
}

@keyframes fall-254 {
  51.757% {
    transform: translate(3.6655vw, 51.757vh) scale(0.5044);
    top: 51.757vh;
  }

  to {
    transform: translate(2.8778vw, 100vh) scale(0.5044);
    top: 100%;
  }
}

.snow:nth-child(255) {
  opacity: 0.9677;
  transform: translate(86.9602vw, -10px) scale(0.3724);
  animation: fall-255 88s -31s linear infinite;
}

@keyframes fall-255 {
  47.726% {
    transform: translate(92.206vw, 47.726vh) scale(0.3724);
    top: 47.726vh;
  }

  to {
    transform: translate(89.5831vw, 100vh) scale(0.3724);
    top: 100%;
  }
}

.snow:nth-child(256) {
  opacity: 0.5102;
  transform: translate(11.4121vw, -10px) scale(0.9639);
  animation: fall-256 84s -7s linear infinite;
}

@keyframes fall-256 {
  64.748% {
    transform: translate(6.1572vw, 64.748vh) scale(0.9639);
    top: 64.748vh;
  }

  to {
    transform: translate(8.78465vw, 100vh) scale(0.9639);
    top: 100%;
  }
}

.snow:nth-child(257) {
  opacity: 0.8514;
  transform: translate(66.5849vw, -10px) scale(0.9253);
  animation: fall-257 115s -13s linear infinite;
}

@keyframes fall-257 {
  74.385% {
    transform: translate(71.5629vw, 74.385vh) scale(0.9253);
    top: 74.385vh;
  }

  to {
    transform: translate(69.0739vw, 100vh) scale(0.9253);
    top: 100%;
  }
}

.snow:nth-child(258) {
  opacity: 0.4559;
  transform: translate(43.0868vw, -10px) scale(0.31);
  animation: fall-258 119s -74s linear infinite;
}

@keyframes fall-258 {
  38.223% {
    transform: translate(46.1091vw, 38.223vh) scale(0.31);
    top: 38.223vh;
  }

  to {
    transform: translate(44.59795vw, 100vh) scale(0.31);
    top: 100%;
  }
}

.snow:nth-child(259) {
  opacity: 0.6705;
  transform: translate(36.1457vw, -10px) scale(0.5333);
  animation: fall-259 115s -70s linear infinite;
}

@keyframes fall-259 {
  48.399% {
    transform: translate(44.695vw, 48.399vh) scale(0.5333);
    top: 48.399vh;
  }

  to {
    transform: translate(40.42035vw, 100vh) scale(0.5333);
    top: 100%;
  }
}

.snow:nth-child(260) {
  opacity: 0.9043;
  transform: translate(13.6246vw, -10px) scale(0.0056);
  animation: fall-260 90s -72s linear infinite;
}

@keyframes fall-260 {
  70.872% {
    transform: translate(21.6732vw, 70.872vh) scale(0.0056);
    top: 70.872vh;
  }

  to {
    transform: translate(17.6489vw, 100vh) scale(0.0056);
    top: 100%;
  }
}

.snow:nth-child(261) {
  opacity: 0.0151;
  transform: translate(30.0887vw, -10px) scale(0.5552);
  animation: fall-261 122s -7s linear infinite;
}

@keyframes fall-261 {
  46.014% {
    transform: translate(35.7024vw, 46.014vh) scale(0.5552);
    top: 46.014vh;
  }

  to {
    transform: translate(32.89555vw, 100vh) scale(0.5552);
    top: 100%;
  }
}

.snow:nth-child(262) {
  opacity: 0.2455;
  transform: translate(20.976vw, -10px) scale(0.5805);
  animation: fall-262 95s -40s linear infinite;
}

@keyframes fall-262 {
  32.106% {
    transform: translate(27.3456vw, 32.106vh) scale(0.5805);
    top: 32.106vh;
  }

  to {
    transform: translate(24.1608vw, 100vh) scale(0.5805);
    top: 100%;
  }
}

.snow:nth-child(263) {
  opacity: 0.7952;
  transform: translate(6.5517vw, -10px) scale(0.7275);
  animation: fall-263 101s -43s linear infinite;
}

@keyframes fall-263 {
  43.001% {
    transform: translate(14.6236vw, 43.001vh) scale(0.7275);
    top: 43.001vh;
  }

  to {
    transform: translate(10.58765vw, 100vh) scale(0.7275);
    top: 100%;
  }
}

.snow:nth-child(264) {
  opacity: 0.2574;
  transform: translate(10.8979vw, -10px) scale(0.032);
  animation: fall-264 102s -47s linear infinite;
}

@keyframes fall-264 {
  30.929% {
    transform: translate(5.8298vw, 30.929vh) scale(0.032);
    top: 30.929vh;
  }

  to {
    transform: translate(8.36385vw, 100vh) scale(0.032);
    top: 100%;
  }
}

.snow:nth-child(265) {
  opacity: 0.0583;
  transform: translate(48.3247vw, -10px) scale(0.6104);
  animation: fall-265 103s -68s linear infinite;
}

@keyframes fall-265 {
  39.937% {
    transform: translate(50.1676vw, 39.937vh) scale(0.6104);
    top: 39.937vh;
  }

  to {
    transform: translate(49.24615vw, 100vh) scale(0.6104);
    top: 100%;
  }
}

.snow:nth-child(266) {
  opacity: 0.9759;
  transform: translate(42.4472vw, -10px) scale(0.9091);
  animation: fall-266 93s -27s linear infinite;
}

@keyframes fall-266 {
  68.76% {
    transform: translate(33.4832vw, 68.76vh) scale(0.9091);
    top: 68.76vh;
  }

  to {
    transform: translate(37.9652vw, 100vh) scale(0.9091);
    top: 100%;
  }
}

.snow:nth-child(267) {
  opacity: 0.6046;
  transform: translate(25.6685vw, -10px) scale(0.7973);
  animation: fall-267 116s -75s linear infinite;
}

@keyframes fall-267 {
  45.927% {
    transform: translate(24.0255vw, 45.927vh) scale(0.7973);
    top: 45.927vh;
  }

  to {
    transform: translate(24.847vw, 100vh) scale(0.7973);
    top: 100%;
  }
}

.snow:nth-child(268) {
  opacity: 0.1308;
  transform: translate(29.3857vw, -10px) scale(0.0673);
  animation: fall-268 119s -32s linear infinite;
}

@keyframes fall-268 {
  79.316% {
    transform: translate(20.5269vw, 79.316vh) scale(0.0673);
    top: 79.316vh;
  }

  to {
    transform: translate(24.9563vw, 100vh) scale(0.0673);
    top: 100%;
  }
}

.snow:nth-child(269) {
  opacity: 0.6626;
  transform: translate(83.2135vw, -10px) scale(0.331);
  animation: fall-269 109s -52s linear infinite;
}

@keyframes fall-269 {
  44.777% {
    transform: translate(75.9518vw, 44.777vh) scale(0.331);
    top: 44.777vh;
  }

  to {
    transform: translate(79.58265vw, 100vh) scale(0.331);
    top: 100%;
  }
}

.snow:nth-child(270) {
  opacity: 0.8937;
  transform: translate(57.1338vw, -10px) scale(0.593);
  animation: fall-270 98s -17s linear infinite;
}

@keyframes fall-270 {
  78.793% {
    transform: translate(53.4605vw, 78.793vh) scale(0.593);
    top: 78.793vh;
  }

  to {
    transform: translate(55.29715vw, 100vh) scale(0.593);
    top: 100%;
  }
}

.snow:nth-child(271) {
  opacity: 0.9083;
  transform: translate(63.1199vw, -10px) scale(0.8043);
  animation: fall-271 86s -60s linear infinite;
}

@keyframes fall-271 {
  39.522% {
    transform: translate(70.0403vw, 39.522vh) scale(0.8043);
    top: 39.522vh;
  }

  to {
    transform: translate(66.5801vw, 100vh) scale(0.8043);
    top: 100%;
  }
}

.snow:nth-child(272) {
  opacity: 0.1703;
  transform: translate(77.2411vw, -10px) scale(0.8335);
  animation: fall-272 86s -34s linear infinite;
}

@keyframes fall-272 {
  36.578% {
    transform: translate(81.5119vw, 36.578vh) scale(0.8335);
    top: 36.578vh;
  }

  to {
    transform: translate(79.3765vw, 100vh) scale(0.8335);
    top: 100%;
  }
}

.snow:nth-child(273) {
  opacity: 0.032;
  transform: translate(87.361vw, -10px) scale(0.2537);
  animation: fall-273 94s -26s linear infinite;
}

@keyframes fall-273 {
  36.426% {
    transform: translate(91.4793vw, 36.426vh) scale(0.2537);
    top: 36.426vh;
  }

  to {
    transform: translate(89.42015vw, 100vh) scale(0.2537);
    top: 100%;
  }
}

.snow:nth-child(274) {
  opacity: 0.0851;
  transform: translate(16.3693vw, -10px) scale(0.86);
  animation: fall-274 103s -100s linear infinite;
}

@keyframes fall-274 {
  37.173% {
    transform: translate(13.9806vw, 37.173vh) scale(0.86);
    top: 37.173vh;
  }

  to {
    transform: translate(15.17495vw, 100vh) scale(0.86);
    top: 100%;
  }
}

.snow:nth-child(275) {
  opacity: 0.0059;
  transform: translate(74.4271vw, -10px) scale(0.8206);
  animation: fall-275 85s -8s linear infinite;
}

@keyframes fall-275 {
  31.255% {
    transform: translate(72.9293vw, 31.255vh) scale(0.8206);
    top: 31.255vh;
  }

  to {
    transform: translate(73.6782vw, 100vh) scale(0.8206);
    top: 100%;
  }
}

.snow:nth-child(276) {
  opacity: 0.7622;
  transform: translate(7.1827vw, -10px) scale(0.0025);
  animation: fall-276 118s -31s linear infinite;
}

@keyframes fall-276 {
  45.876% {
    transform: translate(6.4412vw, 45.876vh) scale(0.0025);
    top: 45.876vh;
  }

  to {
    transform: translate(6.81195vw, 100vh) scale(0.0025);
    top: 100%;
  }
}

.snow:nth-child(277) {
  opacity: 0.3025;
  transform: translate(23.5552vw, -10px) scale(0.9137);
  animation: fall-277 85s -97s linear infinite;
}

@keyframes fall-277 {
  74.631% {
    transform: translate(24.104vw, 74.631vh) scale(0.9137);
    top: 74.631vh;
  }

  to {
    transform: translate(23.8296vw, 100vh) scale(0.9137);
    top: 100%;
  }
}

.snow:nth-child(278) {
  opacity: 0.166;
  transform: translate(57.9672vw, -10px) scale(0.5048);
  animation: fall-278 125s -59s linear infinite;
}

@keyframes fall-278 {
  41.343% {
    transform: translate(51.3894vw, 41.343vh) scale(0.5048);
    top: 41.343vh;
  }

  to {
    transform: translate(54.6783vw, 100vh) scale(0.5048);
    top: 100%;
  }
}

.snow:nth-child(279) {
  opacity: 0.2048;
  transform: translate(84.4528vw, -10px) scale(0.1383);
  animation: fall-279 97s -82s linear infinite;
}

@keyframes fall-279 {
  39.127% {
    transform: translate(79.7811vw, 39.127vh) scale(0.1383);
    top: 39.127vh;
  }

  to {
    transform: translate(82.11695vw, 100vh) scale(0.1383);
    top: 100%;
  }
}

.snow:nth-child(280) {
  opacity: 0.9158;
  transform: translate(51.6211vw, -10px) scale(0.0787);
  animation: fall-280 114s -68s linear infinite;
}

@keyframes fall-280 {
  65.048% {
    transform: translate(49.3715vw, 65.048vh) scale(0.0787);
    top: 65.048vh;
  }

  to {
    transform: translate(50.4963vw, 100vh) scale(0.0787);
    top: 100%;
  }
}

.snow:nth-child(281) {
  opacity: 0.2821;
  transform: translate(10.681vw, -10px) scale(0.5911);
  animation: fall-281 122s -83s linear infinite;
}

@keyframes fall-281 {
  72.453% {
    transform: translate(12.3849vw, 72.453vh) scale(0.5911);
    top: 72.453vh;
  }

  to {
    transform: translate(11.53295vw, 100vh) scale(0.5911);
    top: 100%;
  }
}

.snow:nth-child(282) {
  opacity: 0.3669;
  transform: translate(74.9571vw, -10px) scale(0.1084);
  animation: fall-282 112s -94s linear infinite;
}

@keyframes fall-282 {
  31.422% {
    transform: translate(81.3199vw, 31.422vh) scale(0.1084);
    top: 31.422vh;
  }

  to {
    transform: translate(78.1385vw, 100vh) scale(0.1084);
    top: 100%;
  }
}

.snow:nth-child(283) {
  opacity: 0.2043;
  transform: translate(37.0799vw, -10px) scale(0.1492);
  animation: fall-283 83s -83s linear infinite;
}

@keyframes fall-283 {
  53.661% {
    transform: translate(38.4708vw, 53.661vh) scale(0.1492);
    top: 53.661vh;
  }

  to {
    transform: translate(37.77535vw, 100vh) scale(0.1492);
    top: 100%;
  }
}

.snow:nth-child(284) {
  opacity: 0.7004;
  transform: translate(64.8402vw, -10px) scale(0.0782);
  animation: fall-284 87s -25s linear infinite;
}

@keyframes fall-284 {
  47.869% {
    transform: translate(59.2868vw, 47.869vh) scale(0.0782);
    top: 47.869vh;
  }

  to {
    transform: translate(62.0635vw, 100vh) scale(0.0782);
    top: 100%;
  }
}

.snow:nth-child(285) {
  opacity: 0.569;
  transform: translate(10.4855vw, -10px) scale(0.3894);
  animation: fall-285 107s -52s linear infinite;
}

@keyframes fall-285 {
  37.334% {
    transform: translate(18.4739vw, 37.334vh) scale(0.3894);
    top: 37.334vh;
  }

  to {
    transform: translate(14.4797vw, 100vh) scale(0.3894);
    top: 100%;
  }
}

.snow:nth-child(286) {
  opacity: 0.2912;
  transform: translate(49.3364vw, -10px) scale(0.347);
  animation: fall-286 88s -99s linear infinite;
}

@keyframes fall-286 {
  37.833% {
    transform: translate(47.113vw, 37.833vh) scale(0.347);
    top: 37.833vh;
  }

  to {
    transform: translate(48.2247vw, 100vh) scale(0.347);
    top: 100%;
  }
}

.snow:nth-child(287) {
  opacity: 0.4262;
  transform: translate(32.0985vw, -10px) scale(0.4412);
  animation: fall-287 97s -89s linear infinite;
}

@keyframes fall-287 {
  52.29% {
    transform: translate(28.858vw, 52.29vh) scale(0.4412);
    top: 52.29vh;
  }

  to {
    transform: translate(30.47825vw, 100vh) scale(0.4412);
    top: 100%;
  }
}

.snow:nth-child(288) {
  opacity: 0.3896;
  transform: translate(14.2413vw, -10px) scale(0.7008);
  animation: fall-288 127s -59s linear infinite;
}

@keyframes fall-288 {
  60.338% {
    transform: translate(6.8258vw, 60.338vh) scale(0.7008);
    top: 60.338vh;
  }

  to {
    transform: translate(10.53355vw, 100vh) scale(0.7008);
    top: 100%;
  }
}

.snow:nth-child(289) {
  opacity: 0.0449;
  transform: translate(0.7553vw, -10px) scale(0.4649);
  animation: fall-289 108s -55s linear infinite;
}

@keyframes fall-289 {
  50.794% {
    transform: translate(-3.1674vw, 50.794vh) scale(0.4649);
    top: 50.794vh;
  }

  to {
    transform: translate(-1.20605vw, 100vh) scale(0.4649);
    top: 100%;
  }
}

.snow:nth-child(290) {
  opacity: 0.0243;
  transform: translate(52.2599vw, -10px) scale(0.8409);
  animation: fall-290 106s -94s linear infinite;
}

@keyframes fall-290 {
  71.505% {
    transform: translate(62.1285vw, 71.505vh) scale(0.8409);
    top: 71.505vh;
  }

  to {
    transform: translate(57.1942vw, 100vh) scale(0.8409);
    top: 100%;
  }
}

.snow:nth-child(291) {
  opacity: 0.9525;
  transform: translate(62.8339vw, -10px) scale(0.5298);
  animation: fall-291 95s -99s linear infinite;
}

@keyframes fall-291 {
  74.519% {
    transform: translate(67.0348vw, 74.519vh) scale(0.5298);
    top: 74.519vh;
  }

  to {
    transform: translate(64.93435vw, 100vh) scale(0.5298);
    top: 100%;
  }
}

.snow:nth-child(292) {
  opacity: 0.6486;
  transform: translate(21.1859vw, -10px) scale(0.9228);
  animation: fall-292 125s -94s linear infinite;
}

@keyframes fall-292 {
  40.477% {
    transform: translate(29.9823vw, 40.477vh) scale(0.9228);
    top: 40.477vh;
  }

  to {
    transform: translate(25.5841vw, 100vh) scale(0.9228);
    top: 100%;
  }
}

.snow:nth-child(293) {
  opacity: 0.8275;
  transform: translate(17.0834vw, -10px) scale(0.4127);
  animation: fall-293 126s -5s linear infinite;
}

@keyframes fall-293 {
  44.965% {
    transform: translate(23.0742vw, 44.965vh) scale(0.4127);
    top: 44.965vh;
  }

  to {
    transform: translate(20.0788vw, 100vh) scale(0.4127);
    top: 100%;
  }
}

.snow:nth-child(294) {
  opacity: 0.3994;
  transform: translate(54.7269vw, -10px) scale(0.6577);
  animation: fall-294 81s -8s linear infinite;
}

@keyframes fall-294 {
  47.975% {
    transform: translate(61.4646vw, 47.975vh) scale(0.6577);
    top: 47.975vh;
  }

  to {
    transform: translate(58.09575vw, 100vh) scale(0.6577);
    top: 100%;
  }
}

.snow:nth-child(295) {
  opacity: 0.3753;
  transform: translate(72.0923vw, -10px) scale(0.4959);
  animation: fall-295 98s -71s linear infinite;
}

@keyframes fall-295 {
  65.792% {
    transform: translate(66.6042vw, 65.792vh) scale(0.4959);
    top: 65.792vh;
  }

  to {
    transform: translate(69.34825vw, 100vh) scale(0.4959);
    top: 100%;
  }
}

.snow:nth-child(296) {
  opacity: 0.6311;
  transform: translate(67.8965vw, -10px) scale(0.7554);
  animation: fall-296 105s -69s linear infinite;
}

@keyframes fall-296 {
  40.029% {
    transform: translate(77.3404vw, 40.029vh) scale(0.7554);
    top: 40.029vh;
  }

  to {
    transform: translate(72.61845vw, 100vh) scale(0.7554);
    top: 100%;
  }
}

.snow:nth-child(297) {
  opacity: 0.3764;
  transform: translate(48.7886vw, -10px) scale(0.5214);
  animation: fall-297 126s -37s linear infinite;
}

@keyframes fall-297 {
  41.921% {
    transform: translate(43.3943vw, 41.921vh) scale(0.5214);
    top: 41.921vh;
  }

  to {
    transform: translate(46.09145vw, 100vh) scale(0.5214);
    top: 100%;
  }
}

.snow:nth-child(298) {
  opacity: 0.9469;
  transform: translate(71.9874vw, -10px) scale(0.0289);
  animation: fall-298 118s -73s linear infinite;
}

@keyframes fall-298 {
  35.47% {
    transform: translate(71.1295vw, 35.47vh) scale(0.0289);
    top: 35.47vh;
  }

  to {
    transform: translate(71.55845vw, 100vh) scale(0.0289);
    top: 100%;
  }
}

.snow:nth-child(299) {
  opacity: 0.8403;
  transform: translate(91.5437vw, -10px) scale(0.0513);
  animation: fall-299 101s -97s linear infinite;
}

@keyframes fall-299 {
  56.392% {
    transform: translate(100.5807vw, 56.392vh) scale(0.0513);
    top: 56.392vh;
  }

  to {
    transform: translate(96.0622vw, 100vh) scale(0.0513);
    top: 100%;
  }
}

.snow:nth-child(300) {
  opacity: 0.8495;
  transform: translate(6.6914vw, -10px) scale(0.4148);
  animation: fall-300 123s -59s linear infinite;
}

@keyframes fall-300 {
  48.083% {
    transform: translate(14.353vw, 48.083vh) scale(0.4148);
    top: 48.083vh;
  }

  to {
    transform: translate(10.5222vw, 100vh) scale(0.4148);
    top: 100%;
  }
}
